import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import DeleteSVG from '../../../../../../public/media/delete-icon.svg';
import EditSVG from '../../../../../../public/media/edit-icon.svg';
import { PopoverPlacement } from '../../../../../UIKit/Popover/Popover';
import PopoverOptions from '../../../../../UIKit/PopoverOptions/PopoverOptions';
import { IProjectRisk, ProjectRisksActionType } from '../types';
import styles from './ProjectRisksList.module.scss';

interface IProjectRisksListProps {
  risks: IProjectRisk[];
  onRiskActionHandler: (action: ProjectRisksActionType, risk: IProjectRisk) => void;
}

interface IProjectRisksListItemProps {
  t: (key: string) => string;
  risk: IProjectRisk;
  onRiskActionHandler: (action: ProjectRisksActionType, risk: IProjectRisk) => void;
}

const ProjectRisksListItem = ({ t, risk, onRiskActionHandler }: IProjectRisksListItemProps) => {
  const explanationTextRef = useRef<HTMLParagraphElement>(null);
  const mitigationContainerRef = useRef<HTMLParagraphElement>(null);
  const ownerContainerRef = useRef<HTMLParagraphElement>(null);

  const [isOverflowed, setIsOverflowed] = useState<{
    explanation: boolean;
    mitigation: boolean;
    owner: boolean;
  }>({
    explanation: false,
    mitigation: false,
    owner: false,
  });

  const checkOverflow = () => {
    const explanationContainer = explanationTextRef.current;
    const mitigationContainer = mitigationContainerRef.current;
    const ownerContainer = ownerContainerRef.current;

    if (explanationContainer && mitigationContainer && ownerContainer) {
      setIsOverflowed({
        explanation: explanationContainer.scrollWidth > explanationContainer.clientWidth
          || explanationContainer.scrollHeight > explanationContainer.clientHeight,
        mitigation: mitigationContainer.scrollWidth > mitigationContainer.clientWidth
          || mitigationContainer.scrollHeight > mitigationContainer.clientHeight,
        owner: ownerContainer.scrollWidth > ownerContainer.clientWidth
          || ownerContainer.scrollHeight > ownerContainer.clientHeight,
      });
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [risk]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <div
      key={risk.id}
      className={styles.projectRisksListItem}
    >
      <header className={styles.projectRisksListItem__header}>
        <p className={styles.caption}>{risk.caption}</p>
        <PopoverOptions
          options={[
            {
              id: 0,
              title: (
                <div className={styles.option}>
                  <svg>
                    <use
                      xlinkHref={`${EditSVG}#editSVG`}
                      href={`${EditSVG}#editSVG`}
                    />
                  </svg>
                  <p>{t('Edit risk')}</p>
                </div>
              ),
              handler: () => onRiskActionHandler?.(ProjectRisksActionType.EDIT, risk),
            },
            {
              id: 1,
              title: (
                <div className={classNames(styles.option, styles.option_delete)}>
                  <svg>
                    <use
                      xlinkHref={`${DeleteSVG}#deleteSVG`}
                      href={`${DeleteSVG}#deleteSVG`}
                    />
                  </svg>
                  <p>{t('Delete risk')}</p>
                </div>
              ),
              handler: () => onRiskActionHandler?.(ProjectRisksActionType.DELETE, risk),
            },
          ]}
          paperClassName={styles.navigation__mobile__paper}
          placement={PopoverPlacement.CONTEXT_MENU}
        />
      </header>
      <div className={styles.projectRisksListItem__description}>
        <figure className={styles.projectRisksListItem__description__item}>
          <figcaption>{t('Risk Explanation')}</figcaption>
          <div className={styles.value}>
            <p ref={explanationTextRef}>{risk.explanation || '-'}</p>
            {isOverflowed.explanation && (
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                arrow
                title={(
                  <div className={styles.tooltipContent}>
                    <p className={styles.tooltipContent__title}>{t('Risk Explanation')}</p>
                    <p className={styles.tooltipContent__text}>{risk.explanation}</p>
                  </div>
                )}
                placement='bottom-start'
              >
                <span className={styles.hoverElement}>...</span>
              </Tooltip>
            )}
          </div>
        </figure>
        <figure className={styles.projectRisksListItem__description__item}>
          <figcaption>{t('Risk Mitigation')}</figcaption>
          <div className={styles.value}>
            <p ref={mitigationContainerRef}>{risk.mitigation || '-'}</p>
            {isOverflowed.mitigation && (
              <Tooltip
                enterTouchDelay={0}
                disableFocusListener
                arrow
                title={(
                  <div className={styles.tooltipContent}>
                    <p className={styles.tooltipContent__title}>{t('Risk Mitigation')}</p>
                    <p className={styles.tooltipContent__text}>{risk.mitigation}</p>
                  </div>
                )}
                placement='bottom-start'
              >
                <span className={styles.hoverElement}>...</span>
              </Tooltip>
            )}
          </div>
        </figure>
        <div className={styles.twoColumns}>
          <figure
            className={classNames(styles.projectRisksListItem__description__item, styles.projectRisksListItem__description__item_half)}
          >
            <figcaption>{t('Owner')}</figcaption>
            <div className={styles.value}>
              <p ref={ownerContainerRef}>{risk.owner}</p>
              {isOverflowed.owner && (
                <Tooltip
                  enterTouchDelay={0}
                  disableFocusListener
                  arrow
                  title={(
                    <div className={styles.tooltipContent}>
                      <p className={styles.tooltipContent__title}>{t('Owner')}</p>
                      <p className={styles.tooltipContent__text}>{risk.owner}</p>
                    </div>
                  )}
                  placement='bottom-start'
                >
                  <span className={styles.hoverElement}>...</span>
                </Tooltip>
              )}
            </div>
          </figure>
          <figure
            className={classNames(styles.projectRisksListItem__description__item, styles.projectRisksListItem__description__item_half)}
          >
            <figcaption>{t('Due date')}</figcaption>
            <div className={styles.value}>
              <p>{risk.due_date}</p>
            </div>
          </figure>
        </div>
      </div>
    </div>
  );
};

const ProjectRisksList = ({ risks, onRiskActionHandler }: IProjectRisksListProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.projectRisksList}>
      {risks.map((risk) => (
        <ProjectRisksListItem
          t={t}
          key={risk.id}
          risk={risk}
          onRiskActionHandler={onRiskActionHandler}
        />
      ))}
    </div>
  );
};

export default ProjectRisksList;
