import * as Yup from 'yup';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { mutate } from 'swr';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';

import Input from '../../../UIKit/Input/Input';
import Button from '../../../UIKit/Button/Button';
import DragDropFileUploadArea from '../../../UIKit/DragDropFileUploadArea/DragDropFileUploadArea';

import apiClient from '../../../../apiClient';
import { InitialTemplateFormValuesType } from '../types';

import ExcelSVG from '../../../../public/media/excel-icon.svg';
import CloseSVG from '../../../../public/media/close.svg';
import InfoSVG from '../../../../public/media/info.svg';

import styles from './TemplateForm.module.scss';

const initialValues: InitialTemplateFormValuesType = { titleEn: '', titleDe: '', file: null };

type TemplateTokenResponse = {
  token: string;
  expires_at: string;
};

type TemplateFormProps = {
  closeDrawer: () => void;
  template?: (InitialTemplateFormValuesType & { id: number }) | null;
  submitButtonText?: string;
  onCreateNewTemplate?: () => void;
  dateFormat: string;
  isFileEditable?: boolean;
};

const getToken = async (clientId?: string) => {
  try {
    const { response } = await apiClient
      .post<{ data: TemplateTokenResponse }>(`clients/${clientId}/templates/upload`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const TemplateForm = ({
  closeDrawer, template, submitButtonText, onCreateNewTemplate, dateFormat, isFileEditable,
}: TemplateFormProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const fileInput = useRef<null | HTMLInputElement>(null);

  // TODO: remove when the error notification is implemented
  const [requestError, setRequestError] = useState<string | null>(null);

  const uploadFile = async (token: string, body: FormData) => {
    try {
      const uploadResponse = await apiClient.post('upload', { body });
      if (uploadResponse.statusCode === 201) {
        return { token, uploadResponse };
      } else {
        throw new Error('Failed to upload file');
      }
    } catch (e) {
      console.error(e);
      setRequestError(e.message);
    }
  };

  const saveFile = async (file: File, clientId: string) => {
    try {
      const tokenResponse = await getToken(clientId);
      if (tokenResponse?.token) {
        const formData = new FormData();
        formData.append('token', tokenResponse.token);
        formData.append('file', file);
        return await uploadFile(tokenResponse.token, formData);
      } else {
        throw new Error('Failed to get token');
      }
    } catch (e) {
      console.error(e);
      setRequestError(e.message);
    }
  };

  const editTemplate = async (editableTemplate: InitialTemplateFormValuesType & { id: number }) => {
    let newFileToken: string | null = null;
    try {
      if (editableTemplate.file && editableTemplate.file?.created_at !== template?.file?.created_at && params.id) {
        const response = await saveFile(editableTemplate.file, params.id);
        newFileToken = response?.token ?? null;
      }
      const { statusCode } = await apiClient.put(`clients/${params.id}/templates/${editableTemplate.id}`, {
        body: JSON.stringify({
          caption: {
            en: editableTemplate.titleEn,
            de: editableTemplate.titleDe,
          },
          ...(newFileToken ? { file: newFileToken } : {}),
        }),
      });

      if (statusCode === 200) {
        await mutate((key: any[]) => key.includes('clients/templates'), undefined, { revalidate: true });
        closeDrawer();
      }
    } catch (e) {
      console.error(e);
      setRequestError(e.message);
    }
  };

  const createTemplate = async (formValues: InitialTemplateFormValuesType, clientId?: string) => {
    if (formValues.file && clientId) {
      try {
        const response = await saveFile(formValues.file, clientId);
        const body = JSON.stringify({
          caption: {
            en: formValues.titleEn,
            de: formValues.titleDe,
          },
          file: response?.token,
        });
        if (response?.uploadResponse.statusCode === 201) {
          await apiClient.post(`clients/${params.id}/templates`, { body });
          await mutate((key: any[]) => key.includes('clients/templates'), undefined, { revalidate: true });
          closeDrawer();
        }
      } catch (e) {
        console.error(e);
        setRequestError(e.message);
      }
    }
  };

  const submitForm = async (formValues: InitialTemplateFormValuesType) => {
    setRequestError(null);
    await (template ? editTemplate({ ...formValues, id: template.id }) : createTemplate(formValues, params.id));
  };

  const {
    values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldError, isSubmitting, setTouched,
  } = useFormik({
    initialValues: template || initialValues,
    onSubmit: (formValues) => submitForm(formValues),
    validationSchema: Yup.object({
      titleEn: Yup.string().trim()
        .max(100, t('The title field must not be greater than 100 characters.'))
        .required(t('Template name is required')),
      titleDe: Yup.string().trim()
        .max(100, t('The title field must not be greater than 100 characters.'))
        .required(t('Template name is required')),
      file: Yup.mixed().required(t('A file is required')),
    }),
  });

  const setExcelFile = (files: FileList | null) => {
    if (files?.length) {
      const allowedExtensions = /(\.xlsx)$/i;
      const SIZE_LIMIT = 1024 ** 2;

      switch (true) {
        case !allowedExtensions.exec(files[0]?.name):
          setTouched({ ...touched, file: true }).then(() => setFieldError('file', t('Please select only .xlsx files.')));
          break;
        case files[0].size > SIZE_LIMIT:
          setTouched({ ...touched, file: true }).then(() => setFieldError('file', t('Size limit is 1MB.')));
          break;
        default:
          setFieldValue('file', files[0]);
          break;
      }
    }
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    const { files } = e.dataTransfer;
    setExcelFile(files);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    setExcelFile(files);
  };

  const onDownloadFileClick = async () => {
    await apiClient.download(
      `clients/${params.id}/templates/${template?.id}/file`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      template?.file?.name ?? 'template.xlsx',
    );
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__block}>
        <h5>{t('Project name')}</h5>
        <Input
          value={values.titleEn}
          setValue={handleChange}
          onBlur={handleBlur}
          id='titleEn'
          label={t('Enter template name')}
          name='titleEn'
          error={!!(touched.titleEn && errors.titleEn)}
          errorMessage={errors.titleEn}
          className={classNames(styles.form__input_en, styles.form__input)}
        />
        <Input
          value={values.titleDe}
          setValue={handleChange}
          onBlur={handleBlur}
          id='titleDe'
          label={t('Enter template name')}
          name='titleDe'
          error={!!(touched.titleDe && errors.titleDe)}
          errorMessage={errors.titleDe}
          className={classNames(styles.form__input_de, styles.form__input)}
        />
        {values.file ? (
          <div className={styles.form__file}>
            <svg className={styles.form__file__svg}>
              <use
                xlinkHref={`${ExcelSVG}#excelIcon`}
                href={`${ExcelSVG}#excelIcon`}
              />
            </svg>
            <div className={styles.form__file__text}>
              <p className={styles.form__file__title}>{values.file.name}</p>
              <p className={styles.form__file__subtitle}>{`${t('Uploaded')} ${dayjs(values.file.created_at).format(dateFormat)}`}</p>
            </div>
            {!template ? (
              <button
                className={styles.form__file__removeBtn}
                aria-label='remove file'
                type='button'
                onClick={() => setFieldValue('file', null)}
              >
                <svg>
                  <use
                    xlinkHref={`${CloseSVG}#closeSVG`}
                    href={`${CloseSVG}#closeSVG`}
                  />
                </svg>
              </button>
            ) : (
              <>
                <button
                  type='button'
                  className={styles.form__file__downloadBtn}
                  onClick={onDownloadFileClick}
                >
                  {t('Download')}
                </button>
                {isFileEditable && (
                <button
                  type='button'
                  className={styles.form__file__removeBtn}
                  onClick={() => setFieldValue('file', null)}
                >
                  <svg>
                    <use
                      xlinkHref={`${CloseSVG}#closeSVG`}
                      href={`${CloseSVG}#closeSVG`}
                    />
                  </svg>
                </button>
                )}
              </>
            )}
          </div>
        ) : (
          <DragDropFileUploadArea
            className={styles.form__uploadArea}
            handleDropFile={handleDropFile}
            handleChangeFile={handleChangeFile}
            touched={!!(touched.file && errors.file)}
            error={errors.file}
            value={values.file}
            ref={fileInput}
          />
        )}
      </div>
      {!!template && (
      <p className={styles.info}>
        <svg className={styles.info__icon}>
          <use
            xlinkHref={`${InfoSVG}#infoSVG`}
            href={`${InfoSVG}#infoSVG`}
          />
        </svg>
        <span className={styles.info__text}>
          {t('In order to upload a new excel file')}
          ,
          {' '}
          <button
            type='button'
            className={styles.info__btn}
            onClick={onCreateNewTemplate}
          >
            {t('create a new project template')}
            .
          </button>
        </span>
      </p>
      )}
      {requestError && <p className={styles.error}>{requestError}</p>}
      <div className={styles.form__footer}>
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitButtonText || t('Save template')}
        </Button>
      </div>
    </form>
  );
};

export default TemplateForm;
