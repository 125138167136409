import { SelectChangeEvent } from '@mui/material';
import classNames from 'classnames';

import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren as CircleProgressBar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../../../../apiClient';
import SettingsSVG from '../../../../../public/media/deliverable_domains/Settings.svg';
import DownloadTemplateModal, { SelectedTemplate } from '../../../../DownloadTemplateModal/DownloadTemplateModal.tsx';
import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import Drawer from '../../../../UIKit/Drawer/Drawer';
import Select from '../../../../UIKit/Select/Select';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { activeTask } from '../../ManageDeliverables/manageDeliverables.atom.ts';
import { Deliverable, DeliverableStatus } from '../../NewProjectDeliverables/types';
import { NewProjectContext } from '../../types';
import { DeliverablesFilterValue, FormattedDeliverables } from '../types';
import DownloadSVG from '../../../../../public/media/simple-download.svg';
import { userClientAtom } from '../../../../../store/auth.ts';
import { Permissions } from '../../../Login/user.props.ts';
import { usePermissions } from '../../../../../usePermission.ts';

import styles from './SummaryDashboard.module.scss';

export const groupDeliverablesByDomain = (deliverables: Deliverable[], filter: DeliverableStatus | 'all') => deliverables
  .filter((deliverable) => (filter === 'all' ? true : deliverable.status.value === filter))
  .reduce(
    (acc, curr) => {
      const isActive = curr.status.value !== DeliverableStatus.DISABLED;
      if (curr.task?.domain) {
        acc.domains[curr.task.domain.id] = {
          ...curr.task?.domain,
          deliverables: [...(isActive ? [curr] : []), ...(acc.domains?.[curr.task?.domain.id]?.deliverables || [])],
        };
        if (isActive) {
          acc.total += 1;
        }
        if (curr.status.value === DeliverableStatus.INTERNAL) {
          acc.internal += 1;
        }
        if (curr.status.value === DeliverableStatus.EXTERNAL) {
          acc.external += 1;
        }
      }

      return acc;
    },
    {
      domains: {},
      internal: 0,
      external: 0,
      total: 0,
      rowData: deliverables,
    } as FormattedDeliverables,
  );

const sortByName = (a: { caption: string }, b: { caption: string }) => {
  let nameA = a.caption.toUpperCase();
  let nameB = b.caption.toUpperCase();
  return Intl.Collator().compare(nameA, nameB);
};

const PLACEHOLDER_MESSAGE = {
  all: 'Deliverables have not been added',
  [String(DeliverableStatus.INTERNAL)]: 'Internal deliverables have not been added',
  [String(DeliverableStatus.EXTERNAL)]: 'External deliverables have not been added',
};

const SummaryDeliverables = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t, i18n } = useTranslation();
  const { projectId, clientId } = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<DeliverablesFilterValue>('all');
  const [filteredDeliverables, setFilteredDeliverables] = useState<FormattedDeliverables | undefined>();
  const setCurrentTask = useSetAtom(activeTask);
  const navigate = useNavigate();
  const currentClient = useAtomValue(userClientAtom);
  const { hasPermission } = usePermissions();

  const { data: deliverables, isLoading, isValidating } = useSWR(
    [`projects/${projectId}/deliverables?page=all&with=scores,task.domain,templates`, i18n.language, 'summary', project?.status?.state],
    async ([url]) => (project?.status?.state !== StatusState.PENDING ? apiClient
      .get<{ data: Deliverable[] }>(url)
      .then(({ response }) => {
        const formattedResponse = groupDeliverablesByDomain(response.data, 'all');
        setFilteredDeliverables(formattedResponse);
        return formattedResponse;
      }) : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    if (deliverables) {
      if (selectedFilter === 'all') {
        setFilteredDeliverables(deliverables);
      } else {
        setFilteredDeliverables(groupDeliverablesByDomain(deliverables.rowData, Number(selectedFilter)));
      }
    }
  }, [selectedFilter]);

  const getDomainStatusPercent = (deliverablesData: FormattedDeliverables | undefined, type: 'internal' | 'external') => (
    (deliverablesData && deliverablesData.total)
      ? Math.round((deliverablesData[type] / deliverablesData.total) * 100) : 0);

  const [selectedTemplates, setSelectedTemplates] = useState<SelectedTemplate[] | null>(null);

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.deliverables, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading && !deliverables ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Deliverables')}</h3>
            <button
              type='button'
              className={classNames('link', styles.detailsButton)}
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            >
              {t('See all')}
            </button>
          </header>
          {deliverables?.total ? (
            <>
              <div className={styles.summary}>
                <div className={styles.summary__items}>
                  <div className={styles.summary__item}>
                    <p className={styles.summary__item__title}>{t('Internal')}</p>
                    <span className={classNames(styles.summary__item__dot, styles.summary__item__dot_internal)} />
                    <p className={styles.summary__item__percentage}>
                      {getDomainStatusPercent(deliverables, 'internal')}
                      %
                    </p>
                  </div>
                  <div className={styles.summary__item}>
                    <p className={styles.summary__item__title}>{t('External')}</p>
                    <span className={classNames(styles.summary__item__dot, styles.summary__item__dot_external)} />
                    <p className={styles.summary__item__percentage}>
                      {getDomainStatusPercent(deliverables, 'external')}
                      %
                    </p>
                  </div>
                </div>
                <div className={styles.summary__diagram}>
                  <CircleProgressBar
                    value={getDomainStatusPercent(deliverables, 'internal')}
                    strokeWidth={16}
                  />
                </div>
              </div>
              <div className={classNames(styles.deliverables__list)}>
                <header
                  className={classNames(styles.list__row, styles.list__header, styles.deliverables__list__row, styles.list__header_nowrap)}
                >
                  <p className={styles.list__row_onLeft}>{t('Domain')}</p>
                  <p className={classNames(styles.list__row_onRight)}>{t('Number of deliverables')}</p>
                </header>
                {deliverables
                  && Object.values(deliverables.domains).map((domain) => !!domain.deliverables.length && (
                    <div
                      key={domain.id}
                      className={classNames(styles.list__row, styles.deliverables__list__row, styles.deliverables__list__item)}
                    >
                      <p>{domain.caption}</p>
                      <p className={classNames(styles.list__row_onRight)}>{domain.deliverables.length}</p>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <EmptyList title={t('Deliverables have not been added')} />
          )}
        </>
      )}
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Deliverables')}
        className={styles.deliverables__drawer}
      >
        <div className={styles.drawerSettings}>
          <div className={styles.deliverables__filter}>
            <p className={styles.deliverables__filter__title}>{t('Filter')}</p>
            <Select
              options={[
                {
                  caption: t('Show all'),
                  value: 'all',
                },
                {
                  caption: t('Internal'),
                  value: String(DeliverableStatus.INTERNAL),
                },
                {
                  caption: t('External'),
                  value: String(DeliverableStatus.EXTERNAL),
                },
              ]}
              value={selectedFilter as string}
              name='deliverablesfilter'
              labelId='deliverablesfilter'
              setValue={(e) => setSelectedFilter((e as SelectChangeEvent).target.value as DeliverablesFilterValue)}
              type='tile'
              className={styles.deliverables__filter__select}
            />
          </div>
          {hasPermission(Permissions.DELIVERABLE_UPDATE) && (
          <Button
            onClick={() => {
              setCurrentTask(null);
              navigate(`/d/client/${clientId}/project/${project.id}/manage-deliverables`);
            }}
            className={styles.manageDeliverablesButton}
            variant={ButtonVariants.SECONDARY}
            icon={(
              <svg>
                <use
                  xlinkHref={`${SettingsSVG}#settingsSVG`}
                  href={`${SettingsSVG}#settingsSVG`}
                />
              </svg>
          )}
            iconSize={{ width: 16, height: 16 }}
          >
            {t('Manage deliverables')}
          </Button>
          )}
        </div>
        {filteredDeliverables && filteredDeliverables.total && Object.values(filteredDeliverables?.domains).length ? (
          Object.values(filteredDeliverables.domains).map((domain) => !!domain.deliverables.length && (
            <div
              key={domain.id}
              className={styles.deliverables__details}
            >
              <h3 className={styles.deliverables__details__title}>{domain.caption}</h3>
              <ul className={styles.deliverables__details__list}>
                {domain.deliverables.sort(sortByName).map((deliverable) => (
                  <li
                    key={deliverable.id}
                    className={styles.deliverables__details__item}
                  >
                    <p className={styles.deliverable}>{deliverable.caption}</p>
                    <div className={styles.flexBlock}>
                      {currentClient.premium_license
                        && deliverable?.templates.length > 0
                        && deliverable.templates.some(template => template.link === null)
                        && (
                        <button
                          type='button'
                          className={styles.downloadTaskButton}
                          onClick={() => setSelectedTemplates(deliverable.templates)}
                        >
                          <svg>
                            <use
                              xlinkHref={`${DownloadSVG}#simpleDownloadSVG`}
                              href={`${DownloadSVG}#simpleDownloadSVG`}
                            />
                          </svg>
                        </button>
                        )}
                      <p className={classNames(styles.status, {
                        [styles.status_internal]: deliverable.status.value === DeliverableStatus.INTERNAL,
                        [styles.status_external]: deliverable.status.value === DeliverableStatus.EXTERNAL,
                      })}
                      >
                        {deliverable.status.caption}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <EmptyList title={t(PLACEHOLDER_MESSAGE[selectedFilter])} />
        )}
      </Drawer>
      {selectedTemplates && (
        <DownloadTemplateModal
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      )}
    </section>
  );
};

export default SummaryDeliverables;
