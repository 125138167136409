import { useTranslation } from 'react-i18next';
import ProjectRisksTable, { RiskItem } from '../ProjectRisksTable/ProjectRisksTable';
import { IProjectRisk } from '../types';
import styles from './AIGeneratedRisks.module.scss';

interface IAIGeneratedRisks {
  risksForSave?: RiskItem[];
  saveRisk?: (risk: RiskItem) => void;
  removeRiskFromSaved?: (riskId: number) => void;
  generatedRisks: IProjectRisk[];
}

const AIGeneratedRisks = ({
  risksForSave, saveRisk, removeRiskFromSaved, generatedRisks,
}: IAIGeneratedRisks) => {
  const { t } = useTranslation();

  return (
    <div className={styles.AIGeneratedRisks}>
      <header className={styles.AIGeneratedRisks__header}>
        <h5 className={styles.AIGeneratedRisks__header__title}>
          {t('AI-Generated risks')}
        </h5>
      </header>
      <ProjectRisksTable
        risksForSave={risksForSave}
        saveRisk={saveRisk}
        removeRiskFromSaved={removeRiskFromSaved}
        risks={generatedRisks}
        isEditMode
      />
    </div>
  );
};

export default AIGeneratedRisks;
