import React, { useEffect } from 'react';
import {
  Outlet, useLocation, useOutletContext, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';

import { useFormik } from 'formik';
import { useMediaQuery } from '@mui/material';
import Button, { ButtonIconPosition, ButtonSize, ButtonVariants } from '../../../../UIKit/Button/Button';
import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover';

import { NewProjectContext } from '../../types';
import { usePermissions } from '../../../../../usePermission';
import { Permissions } from '../../../Login/user.props';
import { userAtom } from '../../../../../store/auth';
import { DEFAULT_DATE_FORMAT, MOBILE_MEDIA_QUERY } from '../../../../../constants';

import GanttSVG from '../../../../../public/media/gantt.svg';
import TableSVG from '../../../../../public/media/table.svg';
import CalendarSVG from '../../../../../public/media/summary/quality-gates/calendar.svg';

import styles from './Schedule.module.scss';
import DatePicker, { DatePickerSize } from '../../../../UIKit/DatePicker/DatePicker';
import apiClient from '../../../../../apiClient';
import { notify } from '../../../../../store/notifications';
import { TaskWithPredecessors } from '../types';

const Schedule = () => {
  const { clientId, projectId } = useParams();
  const context = useOutletContext<NewProjectContext>();
  const location = useLocation();
  const isTableView = location.pathname.includes('table');
  const project = useAtomValue(context.projectAtom);
  const { hasPermission } = usePermissions();
  const { t } = useTranslation();
  const user = useAtomValue(userAtom);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const init = {
    begin: project.begin,
    end: project.end,
  };

  useEffect(() => {
    sessionStorage.setItem('edit-session', crypto.randomUUID());

    return () => {
      sessionStorage.removeItem('edit-session');
    };
  }, []);

  const onSubmit = async (values: typeof init) => {
    try {
      const areDatesEqual = dayjs(values.begin).isSame(project.begin) && dayjs(values.end).isSame(project.end);
      if (!areDatesEqual) {
        const { statusCode } = await apiClient
          .withCustomHeaders({ 'Edit-session': sessionStorage.getItem('edit-session') || '' })
          .put<{ data: TaskWithPredecessors[], max_level: number }>(`projects/${project.id}/edit-schedule/save-project`, {
          body: JSON.stringify({
            begin: dayjs(values.begin).format('YYYY-MM-DD'),
            end: dayjs(values.end).format('YYYY-MM-DD'),
          }),
        });
        if (statusCode === 200) {
          context.setPollingSettings(prev => ({ ...prev, isTriggered: true }));
        }
      }
    } catch (error) {
      notify({ text: error.message ? { body: error.message } : {} });
      console.error(error);
    }
  };

  const {
    values, setFieldValue, errors, touched, handleSubmit,
  } = useFormik({
    initialValues: init,
    onSubmit,
  });

  const fullDateFormat = user?.user?.dateFormat || DEFAULT_DATE_FORMAT;
  const shortDateFormat = fullDateFormat.replace('YYYY', 'YY');
  const dateFormat = isMobile ? shortDateFormat : fullDateFormat;

  return (
    <section>
      <nav className={styles.schedule__nav}>
        <h2>{t('Schedule')}</h2>
        {/* TODO: add mobile styles when design will be ready */}
        <div className={styles.projectDates}>
          <p>
            {t('Start-End Date')}
            :
          </p>
          <Popover
            className={styles.datePopover}
            paperClassName={styles.datePopover__paper}
            placement={PopoverPlacement.SELECT}
            // onOpen={() => {}}
            onClose={handleSubmit}
            triggerButton={(
              <Button
                variant={ButtonVariants.SECONDARY}
                size={ButtonSize.SMALL}
                iconSize={{ width: 12, height: 12 }}
                iconPosition={ButtonIconPosition.LEFT}
                icon={(
                  <svg>
                    <use
                      xlinkHref={`${CalendarSVG}#calendarSVG`}
                      href={`${CalendarSVG}#calendarSVG`}
                    />
                  </svg>
                )}
              >
                {dayjs(project.begin).format(dateFormat)}
                {' '}
                -
                {' '}
                {dayjs(project.end).format(dateFormat)}
              </Button>
              )}
          >
            <form className={styles.projectDateForm}>
              <p className={styles.projectDateForm__title}>{t('Changing project duration')}</p>
              <DatePicker
                label={t('Start date')}
                format={dateFormat}
                value={dayjs(values.begin)}
                setValue={date => setFieldValue('begin', date)}
                error={!!(touched.begin && errors.begin)}
                errorMessage={errors.begin}
                size={DatePickerSize.SMALL}
              />
              <DatePicker
                label={t('End date')}
                format={dateFormat}
                value={dayjs(values.end)}
                setValue={date => setFieldValue('end', date)}
                error={!!(touched.end && errors.end)}
                errorMessage={errors.end}
                size={DatePickerSize.SMALL}
              />
            </form>
          </Popover>
        </div>
        <Button
          link={`/d/client/${clientId}/project/${projectId}/summary/schedule`}
          variant={isTableView ? ButtonVariants.SECONDARY : ButtonVariants.PRIMARY}
          className={styles.schedule__button}
          size={ButtonSize.SMALL}
          iconSize={{ width: 12, height: 12 }}
          icon={(
            <svg>
              <use
                xlinkHref={`${GanttSVG}#ganttSVG`}
                href={`${GanttSVG}#ganttSVG`}
              />
            </svg>
          )}
        >
          {t('Gantt view')}
        </Button>
        {!!hasPermission(Permissions.PROJECT_EDIT) && (
        <Button
          link='table'
          variant={isTableView ? ButtonVariants.PRIMARY : ButtonVariants.SECONDARY}
          className={styles.schedule__button}
          iconSize={{ width: 12, height: 12 }}
          size={ButtonSize.SMALL}
          icon={(
            <svg>
              <use
                xlinkHref={`${TableSVG}#tableSVG`}
                href={`${TableSVG}#tableSVG`}
              />
            </svg>
          )}
        >
          {t('Edit view')}
        </Button>
        )}
      </nav>
      <Outlet context={context} />
    </section>
  );
};

export default Schedule;
