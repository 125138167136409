import React, { useState } from 'react';
import classNames from 'classnames';
import useSWR from 'swr';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Tooltip, useMediaQuery } from '@mui/material';
import CheckboxItem from '../../../../UIKit/CheckboxItem/CheckboxItem';
import Button, { ButtonSize } from '../../../../UIKit/Button/Button';
import Loader from '../../../../Loader/Loader';
import Switch from '../../../../UIKit/Switch/Switch';
import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover';
import { groupDeliverablesByDomain } from './SummaryDeliverables';

import apiClient from '../../../../../apiClient';
import { Deliverable, DeliverableStatus } from '../../NewProjectDeliverables/types';
import { ProjectResource } from '../../../Projects/types';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { notify } from '../../../../../store/notifications';

import AngleDownSVG from '../../../../../public/media/angle-down.svg';
import PdfSVG from '../../../../../public/media/pdf-icon.svg';
import PptxSVG from '../../../../../public/media/pptx-icon.svg';
import InfoSVG from '../../../../../public/media/info.svg';

import styles from './SummaryDashboard.module.scss';
import { MOBILE_MEDIA_QUERY } from '../../../../../constants';

const PRF_FORMATS = {
  PDF: 'application/pdf',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
};

const FORMAT_OPTIONS = [
  {
    id: 'PPTX',
    title: 'Microsoft PowerPoint',
    format: PRF_FORMATS.PPTX,
    icon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PptxSVG}#pptxSVG`}
          href={`${PptxSVG}#pptxSVG`}
        />
      </svg>
    ),
  },
  {
    id: 'PDF',
    title: 'PDF',
    format: PRF_FORMATS.PDF,
    icon: (
      <svg className={styles.downloadButton__icon}>
        <use
          xlinkHref={`${PdfSVG}#pdfSVG`}
          href={`${PdfSVG}#pdfSVG`}
        />
      </svg>
    ),
  },
];

type FormValuesType = {
  projectId: number,
  internal: boolean,
  external: boolean,
  domains: number[],
  format: string,
};

const RFPScope = ({ project, rfpFormat }: { project: ProjectResource, rfpFormat: string | null }) => {
  const { t, i18n } = useTranslation();
  const [closePopover, setClosePopover] = useState<() => void>(() => {});

  const closeCallback = (func: (e: Event) => void) => {
    setClosePopover(() => func);
  };

  const { data: deliverables, isLoading, isValidating } = useSWR(
    [`projects/${project.id}/deliverables?page=all&with=scores,task.domain`, i18n.language, 'summary', project?.status?.state],
    async ([url]) => (project?.status?.state !== StatusState.PENDING ? apiClient
      .get<{ data: Deliverable[] }>(url)
      .then(({ response }) => {
        const formattedResponse = groupDeliverablesByDomain(response.data, 'all');
        return formattedResponse;
      }) : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  const downloadnRfP = async (config: FormikValues) => {
    const { projectId, format, ...restConfig } = config;
    const params = new URLSearchParams({
      ...restConfig,
      domains: restConfig.domains.join(','),
    }).toString();
    try {
      await apiClient
        .download(
          `projects/${projectId}/download-summary?type=rfp-summary&${params}`,
          PRF_FORMATS[format as keyof typeof PRF_FORMATS],
          `schedule.${format}`,
        );
    } catch (error) {
      console.error(error);
      notify({ text: { body: error?.message ?? '' } });
    }
  };

  const initialValues: FormValuesType = {
    projectId: project.id,
    internal: false,
    external: false,
    domains: [],
    format: rfpFormat ?? FORMAT_OPTIONS[0].id,
  };

  const {
    values, handleSubmit, setFieldValue, isSubmitting, errors, touched,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      domains: Yup.array()
        .of(Yup.string())
        .min(1, t('At least one domain is required')),
      internal: Yup.boolean(),
      external: Yup.boolean(),
    }),
    enableReinitialize: true,
    onSubmit: downloadnRfP,
  });

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  return isLoading
    ? <Loader className={styles.rfp__loader} />
    : (
      <section className={classNames(styles.rfp, {
        [styles.rfp_loading]: isSubmitting || isValidating,
      })}
      >
        <div className={styles.rfp__btnsGroup}>
          <Button
            size={ButtonSize.SMALL}
            onClick={() => {
              deliverables && setFieldValue('domains', Object.values(deliverables?.domains)?.map((item) => item.id));
            }}
          >
            {t('Select all')}
          </Button>
          <Button
            size={ButtonSize.SMALL}
            onClick={() => {
              setFieldValue('domains', []);
            }}
          >
            {t('Deselect all')}
          </Button>
        </div>
        <div className={styles.rfp__table}>
          <table>
            <thead>
              <tr>
                <td>{t('Domain')}</td>
                <td>
                  {isMobile ? (
                    <>
                      <Tooltip
                        enterTouchDelay={0}
                        title={t('Number of internal deliverables')}
                      >
                        <svg>
                          <use
                            xlinkHref={`${InfoSVG}#infoSVG`}
                            href={`${InfoSVG}#infoSVG`}
                          />
                        </svg>
                      </Tooltip>
                      {t('Int.')}
                    </>
                  )
                    : t('Number of internal deliverables')}
                </td>
                <td>
                  {isMobile ? (
                    <>
                      <Tooltip
                        enterTouchDelay={0}
                        title={t('Number of external deliverables')}
                      >
                        <svg>
                          <use
                            xlinkHref={`${InfoSVG}#infoSVG`}
                            href={`${InfoSVG}#infoSVG`}
                          />
                        </svg>
                      </Tooltip>
                      {t('Ext.')}
                    </>
                  ) : t('Number of external deliverables')}
                </td>
              </tr>
            </thead>
            <tbody>
              {deliverables && deliverables.total && Object.values(deliverables?.domains).map((domain) => (
                <tr key={domain.id}>
                  <td>
                    <CheckboxItem
                      value={values.domains.includes(domain.id)}
                      onChange={() => {
                        if (values.domains.includes(domain.id)) {
                          setFieldValue('domains', values.domains.filter((item) => item !== domain.id));
                        } else {
                          setFieldValue('domains', [...values.domains, domain.id]);
                        }
                      }}
                      label={domain.caption}
                      labelClassName={styles.rfp__domainLabel}
                    />
                  </td>
                  <td>
                    {domain.deliverables.filter((item) => item.status.value === DeliverableStatus.INTERNAL).length}
                  </td>
                  <td>
                    {domain.deliverables.filter((item) => item.status.value === DeliverableStatus.EXTERNAL).length}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <footer className={styles.rfp__footer}>
          <div className={styles.rfp__switchsWrapper}>
            <div className={styles.rfp__switch}>
              <Switch
                id='external'
                name='external'
                value={values.external}
                onChange={() => setFieldValue('external', !values.external)}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor='external'
              >
                {t('Include external deliverables')}
              </label>
            </div>
            <div className={styles.rfp__switch}>
              <Switch
                id='internal'
                name='internal'
                value={values.internal}
                onChange={() => setFieldValue('internal', !values.internal)}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor='internal'
              >
                {t('Include internal deliverables')}
              </label>
            </div>
          </div>
          <div className={styles.rfp__submitSection}>
            {!!Object.values(errors).length
            && !!Object.values(touched).length
            && <p className={styles.rfp__submitSection__error}>{Object.values(errors)[0]}</p>}
            <Popover
              paperClassName={styles.addons__paper}
              className={styles.downloadButton__wrapper}
              placement={PopoverPlacement.TOP}
              closeCallback={closeCallback}
              disabled={isSubmitting}
              triggerButton={(
                <div className={classNames(styles.downloadButton, styles.downloadButton__rfp)}>
                  {FORMAT_OPTIONS.find((item) => item.id === values.format)?.icon}
                  <svg className={styles.downloadButton__arrow}>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                </div>
            )}
            >
              <div className={styles.addons__options}>
                {FORMAT_OPTIONS.map((item) => (
                  <button
                    key={item.id}
                    type='button'
                    className={classNames(styles.downloadButton, styles.downloadButton_wide)}
                    onClick={() => {
                      setFieldValue('format', item.id);
                      closePopover();
                    }}
                  >
                    {item.icon}
                    <p>{t(item.title)}</p>
                  </button>
                ))}
              </div>
            </Popover>
            <Button
              className={styles.rfp__submitBtn}
              onClick={() => handleSubmit()}
              disabled={(!values.internal && !values.external) || !values.domains.length || isSubmitting}
            >
              {t('Download RfP info')}
            </Button>
          </div>
        </footer>
      </section>
    );
};

export default RFPScope;
