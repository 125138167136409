import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';
import classNames from 'classnames';
import { useSetAtom } from 'jotai/index';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyedMutator } from 'swr/_internal';
import AISVG from '../../public/media/ai.svg';
import CloseSVG from '../../public/media/close.svg';
import PublishSVG from '../../public/media/publish.svg';
import DeleteSVG from '../../public/media/delete-icon.svg';
import { Locale } from '../../store/lang.ts';
import { filterPositionAnchorEl } from '../../store/table.ts';
import { autoTranslateLocale, changeLocalePublishStatus } from './manageTranslationsAPI.ts';
import styles from './MaintainTranslations.module.scss';
import { TranslationDataWithRows } from './types';

type CustomContextMenuProps = {
  locale: Locale,
  onTranslationRemove: (locale: Locale) => void,
  hideMenu: (event: SyntheticEvent<Element, Event>) => void,
  mutateTranslations: KeyedMutator<TranslationDataWithRows>,
};
const CustomContextMenu = ({
  locale, onTranslationRemove, hideMenu, mutateTranslations,
}: CustomContextMenuProps) => {
  const { t } = useTranslation();

  return (
    <>
      <button
        type='button'
        className={styles.contextMenuOption}
        onClick={(e) => {
          hideMenu(e);
          autoTranslateLocale({ localeId: locale.id, t });
        }}
      >
        <svg>
          <use
            xlinkHref={`${AISVG}#aiSVG`}
            href={`${AISVG}#aiSVG`}
          />
        </svg>
        {t('Auto Translate')}
      </button>
      <button
        type='button'
        className={styles.contextMenuOption}
        onClick={async (e) => {
          hideMenu(e);
          await changeLocalePublishStatus({
            id: locale.id,
            t,
            publish: !locale.is_publish,
          });
          await mutateTranslations();
        }}
      >
        <svg>
          <use
            xlinkHref={locale.is_publish ? `${CloseSVG}#closeSVG` : `${PublishSVG}#publishSVG`}
            href={locale.is_publish ? `${CloseSVG}#closeSVG` : `${PublishSVG}#publishSVG`}
          />
        </svg>
        {t(`${locale.is_publish ? 'Unp' : 'P'}ublish translation`)}
      </button>
      <button
        type='button'
        className={classNames(styles.contextMenuOption, styles.contextMenuOption_danger)}
        onClick={(e) => {
          hideMenu(e);
          onTranslationRemove(locale);
        }}
      >
        <svg>
          <use
            xlinkHref={`${DeleteSVG}#deleteSVG`}
            href={`${DeleteSVG}#deleteSVG`}
          />
        </svg>
        {t('Remove translation')}
      </button>
    </>
  );
};

type TableColumnMenuProps = {
  props: GridColumnMenuProps,
  locale: Locale,
  onTranslationRemove: (locale: Locale) => void,
  mutateTranslations: KeyedMutator<TranslationDataWithRows>,
};

export const TableColumnMenu = ({
  props, locale, onTranslationRemove, mutateTranslations,
}: TableColumnMenuProps) => {
  const setFilterButtonEl = useSetAtom(filterPositionAnchorEl);
  const element = props?.open && document.querySelector(`[data-field="${props?.colDef.field}"]`);
  // When the filter panel is opened and the user clicks on the column menu button,
  // the filter panel momentarily appears in an incorrect position.
  // This setTimeout serves as a workaround for the issue related to the anchorEl position.
  // TODO: find a better solution
  setTimeout(() => {
    element && setFilterButtonEl(element);
  }, 100);
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomContextMenuWithProps = () => (
    <CustomContextMenu
      locale={locale}
      onTranslationRemove={onTranslationRemove}
      hideMenu={props.hideMenu}
      mutateTranslations={mutateTranslations}
    />
  );

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
        columnMenuUserItem: CustomContextMenuWithProps,
      }}
    />
  );
};
