import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../UIKit/Modal/Modal';
import styles from './NewProject.module.scss';
import Button, { ButtonVariants } from '../../UIKit/Button/Button';

type EntityBaseModalProps = {
  keepChanges: () => void;
  recalculateSchedule: () => void;
};

const ResetManualChangesConfirm = ({
  keepChanges, recalculateSchedule,
}: EntityBaseModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      rootClassName={styles.modal}
    >
      <div className={styles.modal__content}>
        <h4>{t('Recalculate Schedule')}</h4>
        <p>{t('You have changed your answers. Do you want to keep your manual schedule changes or have the schedule recalculated?')}</p>
        <ul>
          <li>
            {/* eslint-disable-next-line max-len */}
            {t('If you choose to keep the manual changes, no changes will be made to the tasks that were previously adjusted manually. Only the tasks that have not been manually adjusted will be updated.')}
          </li>
          <li>
            {/* eslint-disable-next-line max-len */}
            {t('If you choose to recalculate the schedule, all manual changes, including added or removed tasks and domains, will be discarded, and the schedule will be recalculated.')}
          </li>
        </ul>
        <div className={styles.modal__btns}>
          <Button
            onClick={keepChanges}
            variant={ButtonVariants.SECONDARY}
          >
            {t('Keep Manual Changes')}
          </Button>
          <Button onClick={recalculateSchedule}>{t('Recalculate Schedule')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetManualChangesConfirm;
