import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import {
  Link, Navigate, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { useResetAtom } from 'jotai/utils';
import { useTranslation } from 'react-i18next';
import Button, { ButtonIconPosition, ButtonVariants } from '../../UIKit/Button/Button';
import CheckboxItem from '../../UIKit/CheckboxItem/CheckboxItem';
import Input from '../../UIKit/Input/Input.tsx';
import LoginLogo from '../../../public/media/loginLogo.svg';
import MicrosoftLogo from '../../../public/media/microsoft.svg';
import styles from './Login.module.scss';
import apiClient from '../../../apiClient.ts';
import {
  globalPermissionsAtom, userAtom, userEmailAtom,
} from '../../../store/auth.ts';
import { UserProps } from './user.props.ts';
import ErrorBlock from './Error/Error';
import Loader from '../../Loader/Loader.tsx';
import LangSelector from '../../LangSelector/LangSelector.tsx';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import { notificationsAtom, notify } from '../../../store/notifications.ts';

enum LoginProviders {
  MICROSOFT = 'microsoft',
}

const DEFAUTL_SSO_ERROR_MESSAGE = 'Failed to authorize with Microsoft. Please try another option.';

const Login = () => {
  const { t, i18n } = useTranslation();
  const oauthTokens = new Set();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params = useParams();

  const [user, setUser] = useAtom(userAtom);
  const setGlobalPermissions = useSetAtom(globalPermissionsAtom);
  const [userEmail, setUserEmail] = useAtom(userEmailAtom);
  const resetUserEmail = useResetAtom(userEmailAtom);

  const [emailValue, setEmailValue] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [authCode, setAuthCodeValue] = useState<string>('');
  const [shouldRemember, setShouldRemember] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [oauthError, setOauthError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginToken, setLoginToken] = useState<string>('');

  const [is2FASectionVisible, setIs2FASectionVisible] = useState<boolean>(false);

  const setNotifications = useSetAtom(notificationsAtom);

  const backToLoginForm = () => {
    setIs2FASectionVisible(false);
    setAuthCodeValue('');
    setLoginToken('');
  };

  const setUserBaseAppData = async (response: { data: UserProps, token?: string }) => {
    response.data.permissions && setGlobalPermissions(response.data.permissions);
    if (response.data.user.locale) {
      i18n.services.backendConnector.backend.options.customHeaders = { 'Accept-Language': response.data.user.locale };
      await i18n.changeLanguage(response.data.user.locale);
    }
    setUser({ user: response.data.user, token: response.data.token });
  };

  const oauthAuthorize = async (provider: LoginProviders, code: string) => {
    try {
      const { response, statusCode } = await apiClient.withoutAuth()
        .post<{ data: UserProps; message?: string, token?: string }>(`oauth/${provider}`, {
        body: JSON.stringify({ code }),
      });

      if (statusCode === 423 && response?.token) {
        setLoginToken(response?.token);
        setIs2FASectionVisible(true);
        return;
      }

      if (statusCode !== 200) {
        return setOauthError(response?.message ?? t(DEFAUTL_SSO_ERROR_MESSAGE));
      }

      await setUserBaseAppData(response);

      return navigate('/');
    } catch (e) {
      setOauthError(e.message ?? t(DEFAUTL_SSO_ERROR_MESSAGE));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const confirm2FALogin = async (token: string, code: string) => {
    try {
      const { response, statusCode } = await apiClient.withoutAuth().post<{ data: UserProps, message?: string }>('auth/confirm', {
        body: JSON.stringify({ token, code }),
      });
      if (statusCode === 200) {
        setUserBaseAppData(response);
        return navigate('/');
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      notify({ text: { body: e.message } });
      console.error(e);
    }
  };

  const onSSOLogin = async (provider: LoginProviders, code: string, hasError: boolean) => {
    if (code && provider && !oauthTokens.has(code)) {
      oauthTokens.add(code);
      setIsLoading(true);
      await oauthAuthorize(provider, code as string);
    }

    if (hasError) {
      setOauthError(t('Failed to authorize with Microsoft. Please try another option.'));
    }
  };

  useEffect(() => {
    const code = searchParams.get('code');
    const hasError = searchParams.get('error');

    onSSOLogin(params.provider as LoginProviders, code as string, !!hasError);
  }, []);

  useEffect(() => {
    userEmail && setEmailValue(userEmail);
  }, [userEmail]);

  const onPasswordBasedLogin = async () => {
    try {
      const { statusCode, response } = await apiClient
        .withoutAuth()
        .post<{ data: UserProps, message?: string } & { message?: string, token?: string }>('auth/login', {
        body: JSON.stringify({
          email: emailValue,
          password: passwordValue,
          ...(authCode ? { code: authCode } : {}),
        }),
      });

      if (statusCode === 423 && response?.token) {
        setLoginToken(response?.token);
        is2FASectionVisible ? setLoginError(response?.message ?? '') : setIs2FASectionVisible(true);
        return;
      }

      if (statusCode !== 200) {
        setLoginError(t('Login error. Please make sure your e-mail and password are correct'));
        return;
      }

      setUserBaseAppData(response);

      shouldRemember ? setUserEmail(emailValue) : resetUserEmail();
      return navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const onOauthLogin = async (provider: LoginProviders) => {
    try {
      const { response, statusCode } = await apiClient.withoutAuth()
        .get<{ url: string, token?: string, message?: string }>(`oauth/${provider}`);

      if (statusCode === 423 && response?.token) {
        setLoginToken(response?.token);
        is2FASectionVisible ? setLoginError(response?.message ?? '') : setIs2FASectionVisible(true);
        return;
      }

      if (!response.url) {
        setOauthError(t('Failed to authorize with Microsoft. Please try another option.'));
        return;
      }
      // we should save login page in the history
      window.location.href = response.url;
    } catch (e) {
      console.error(e);
    }
  };

  const onLogin = async () => {
    setLoginError('');
    loginToken ? confirm2FALogin(loginToken, authCode) : onPasswordBasedLogin();
  };

  useEffect(() => {
    if (!user) {
      setNotifications({ notifications: [] });
    }
  }, [user]);

  if (user) {
    return (
      <Navigate
        to='/'
        replace
      />
    );
  }

  return (
    <main className={styles.login}>
      <div className={styles.login__content}>
        <div>
          <div className={styles.logo}>
            <img
              src={LoginLogo}
              alt='ZEEMLESS'
            />
            <LangSelector />
          </div>
        </div>
        {is2FASectionVisible ? (
          <div className={styles.twoFA}>
            <div className={classNames(styles.title, styles.twoFA__heading)}>
              <Button
                type='button'
                className={styles.backButton}
                variant={ButtonVariants.SECONDARY}
                onClick={backToLoginForm}
                icon={(
                  <svg>
                    <use
                      xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                      href={`${AngleDownSVG}#angleDownSVG`}
                    />
                  </svg>
                )}
                iconPosition={ButtonIconPosition.CENTER}
                iconSize={{ width: 18, height: 18 }}
              />
              <p>{t('2FA authentication')}</p>
            </div>
            <p className={styles.twoFA__subtitle}>{t('Enter the 6-digit code generated by your authentication app.')}</p>
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                onLogin();
              }}
            >
              <Input
                value={authCode}
                setValue={(e) => setAuthCodeValue(e.target.value)}
                id='code'
                label={t('Authentication code')}
              />
              {loginError && (
              <ErrorBlock
                message={loginError}
                className={styles.loginError}
              />
              )}
              <Button
                type='submit'
                className={styles.loginButton}
              >
                {t('Complete')}
              </Button>
            </form>
          </div>
        ) : (
          <div>
            <h2 className={styles.title}>{t('Login to account')}</h2>
            {loginError && (
            <ErrorBlock
              message={loginError}
              className={styles.loginError}
            />
            )}
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault();
                onLogin();
              }}
            >
              <Input
                value={emailValue}
                setValue={(e) => setEmailValue(e.target.value)}
                id='email'
                label={t('Email')}
              />
              <Input
                value={passwordValue}
                setValue={(e) => setPasswordValue(e.target.value)}
                id='password'
                label={t('Password')}
                type='password'
              />
              <div className={styles.passwordState}>
                <CheckboxItem
                  value={shouldRemember}
                  onChange={(e) => setShouldRemember(e.target.checked)}
                  label={t('Remember user')}
                  fontSize={12}
                />
                <Link
                  to='/forgot-password'
                  className={classNames(styles.forgotPassword, 'link')}
                >
                  {t('Forgot your password?')}
                </Link>
              </div>
              <Button
                type='submit'
                className={styles.loginButton}
              >
                {t('Continue')}
              </Button>
              {isLoading ? (
                <div className={styles.microsoftLoading}>
                  <Loader className={styles.microsoftLoading__loader} />
                  <p>{t('Waiting for authorization with Microsoft...')}</p>
                </div>
              ) : (
                <>
                  <div className={styles.altLogin}>
                    <span className={styles.altLogin__text}>{t('or')}</span>
                  </div>
                  {oauthError && <ErrorBlock message={oauthError} />}
                  <Button
                    type='button'
                    variant={ButtonVariants.SECONDARY}
                    className={styles.microsoftButton}
                    onClick={() => onOauthLogin(LoginProviders.MICROSOFT)}
                    icon={(
                      <img
                        src={MicrosoftLogo}
                        alt=''
                      />
                  )}
                    iconSize={{ width: 18, height: 18 }}
                  >
                    {t('Log in with Microsoft')}
                  </Button>
                </>
              )}
            </form>
          </div>
        )}
        <p className={styles.copyright}>{t('ZEEMLESS. Copyright {{year}}.', { year: new Date().getFullYear() })}</p>
      </div>
      <div
        className={styles.login__illustration}
        aria-label='Make your visions fly'
        role='img'
      />
    </main>
  );
};

export default Login;
