import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import classNames from 'classnames';
import Header from '../../Header/Header';
import SubHeader from '../../SubHeader/SubHeader';
import Input from '../../UIKit/Input/Input';
import Button from '../../UIKit/Button/Button';
import styles from './PasswordConfig.module.scss';
import apiClient from '../../../apiClient.ts';
import {
  clientPermissionsAtom, globalPermissionsAtom, userAtom, userClientAtom,
} from '../../../store/auth.ts';
import { getFormattedServerErrors } from '../../../utils';
import PasswordStrength from './PasswordStrength/PasswordStrength.tsx';
import { PASSWORD_REGEXP } from '../../../constants.ts';
import LockSVG from '../../../public/media/lock.svg';
import { tablesSortConfig } from '../../../store/table.ts';
import { notify } from '../../../store/notifications.ts';

enum RULES {
  LENGTH = 'at-least-8-characters',
  NUMBER = 'at-least-one-number',
  SPECIAL_CHARACTER = 'at-least-one-special-character',
  CASE = 'upper-and-lower-case',
}

enum SuccessTitle {
  Created = 'Your password has been successfully created!',
  Changed = 'Your password has been successfully changed!',
  Added = 'Your password has been successfully added!',
}

const NewPassword = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useAtom(userAtom);
  const [successTitle, setSuccessTitle] = useState<SuccessTitle>(SuccessTitle.Changed);

  const token = searchParams.get('token');
  const email = searchParams.get('email')?.replace(/ /g, '+');
  const hasTwoFA = searchParams.get('2fa');
  const isNewAccount = searchParams.get('new');
  const userSetPassword = userData?.user?.passEmpty || token;

  const resetUser = useResetAtom(userAtom);
  const resetGlobalPermissions = useResetAtom(globalPermissionsAtom);
  const resetClientPermissions = useResetAtom(clientPermissionsAtom);
  const resetTablesSortConfig = useResetAtom(tablesSortConfig);
  const resetUserClient = useResetAtom(userClientAtom);

  const [TwoFAtoken, setTwoFAtoken] = useState<string>('');

  const [isConfirmViewVisible, setIsConfirmViewVisible] = useState<boolean>(false);

  const RULES_DEFAULT_STATE = [
    { title: 'At least 8 characters', isValid: false, name: RULES.LENGTH },
    { title: 'Upper and lower case', isValid: false, name: RULES.CASE },
    { title: 'At least 1 special character', isValid: false, name: RULES.SPECIAL_CHARACTER },
    { title: 'At least 1 number', isValid: false, name: RULES.NUMBER },
  ];
  const [rules, setRules] = useState(RULES_DEFAULT_STATE);
  const disabled = rules.some(rule => !rule.isValid);

  const resetPassword = async ({ newPassword, confirmNewPassword }: Record<string, string>) => {
    try {
      const { statusCode, response } = await apiClient.withoutAuth().post<{ message?: string }>('user/reset-password', {
        body: JSON.stringify({
          token,
          email,
          password: newPassword,
          password_confirmation: confirmNewPassword,
          ...(TwoFAtoken ? { confirm_action_token: TwoFAtoken } : {}),
        }),
      });

      if (statusCode === 200) {
        resetUser();
        resetGlobalPermissions();
        resetClientPermissions();
        resetTablesSortConfig();
        resetUserClient();

        setSuccessTitle(isNewAccount ? SuccessTitle.Created : SuccessTitle.Changed);
        setIsConfirmViewVisible(true);
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      notify((e.message ? { text: { body: e.message } } : {}));
      console.error(e);
    }
  };

  const markRule = (name: string, status: boolean) => {
    const updated = rules.map(rule => {
      if (rule.name === name) {
        rule.isValid = status;
      }
      return rule;
    });
    setRules(updated);
  };

  const validate = (value: string, name: string): boolean => {
    let res = false;
    switch (name) {
      case RULES.LENGTH: {
        res = value.length >= 8;
        break;
      }
      case RULES.CASE: {
        res = PASSWORD_REGEXP.CASE.test(value);
        break;
      }
      case RULES.SPECIAL_CHARACTER: {
        res = PASSWORD_REGEXP.SPECIAL_CHARACTER.test(value);
        break;
      }
      case RULES.NUMBER: {
        res = PASSWORD_REGEXP.NUMBER.test(value);
        break;
      }
      default:
    }

    markRule(name, res);
    return res;
  };

  const {
    handleSubmit,
    values,
    handleChange,
    isSubmitting,
    touched,
    errors,
    setErrors,
  } = useFormik({
    initialValues: { oldPassword: '', newPassword: '', confirmNewPassword: '' },
    validationSchema: Yup.object({
      oldPassword: Yup.string().test(
        'old-password',
        t('Old password is required'),
        (value) => (userSetPassword ? true : !!value?.length),
      ),
      newPassword: Yup.string()
        .test(RULES.LENGTH, '', (value) => validate(value!, RULES.LENGTH))
        .test(RULES.NUMBER, '', (value) => validate(value!, RULES.NUMBER))
        .test(RULES.SPECIAL_CHARACTER, '', (value) => validate(value!, RULES.SPECIAL_CHARACTER))
        .test(RULES.CASE, '', (value) => validate(value!, RULES.CASE))
        .required(t('New password is required')),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), ''], t('Passwords must match')).required(t('Confirm new password is required')),
    }),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: formikValues => (token ? resetPassword(formikValues) : setPassword(formikValues, !userData?.user.passEmpty)),
  });

  useEffect(() => {
    if (!values.newPassword) {
      setRules(RULES_DEFAULT_STATE);
    }
  }, [values.newPassword]);

  async function setPassword({ oldPassword, newPassword, confirmNewPassword }: Record<string, string>, isNewPassword: boolean) {
    try {
      const { statusCode, response } = await apiClient.post<{ errors?: Record<string, string[]> }>('user/change-password', {
        body: JSON.stringify({
          ...(isNewPassword ? { old_password: oldPassword } : {}),
          password: newPassword,
          password_confirmation: confirmNewPassword,
        }),
      });

      if (statusCode === 200) {
        setSuccessTitle(isNewPassword ? SuccessTitle.Changed : SuccessTitle.Added);
        setIsConfirmViewVisible(true);
        !isNewPassword && setUserData({ user: { ...userData!.user, passEmpty: false }, token: userData!.token });
      } else if (response.errors) {
        setErrors(getFormattedServerErrors(response.errors));
      } else {
        throw new Error();
      }
    } catch (e) {
      notify();
    }
  }

  const confirmTwoFA = async ({ TFA }: { TFA: string }) => {
    try {
      const { statusCode, response } = await apiClient.post<{ confirm_action_token: string, message?: string }>('auth/confirm-action', {
        body: JSON.stringify({ code: TFA, email }),
      });
      if (statusCode === 200) {
        setTwoFAtoken(response.confirm_action_token);
      } else {
        throw new Error(t(response.message || 'Something went wrong'));
      }
    } catch (e) {
      notify((e.message ? { text: { body: e.message } } : {}));
      console.error(e);
    }
  };

  const {
    handleSubmit: TFAFormHandleSubmit,
    values: TFAFormValues,
    handleChange: TFAFormHandleChange,
    isSubmitting: TFAFormIsSubmitting,
    touched: TFAFormTouched,
    errors: TFAFormErrors,
  } = useFormik({
    initialValues: { TFA: '' },
    validationSchema: Yup.object({
      TFA: Yup.string().required(t('2FA code is required')),
    }),
    onSubmit: confirmTwoFA,
  });

  const isNeedToConfirmTwoFA = hasTwoFA && !TwoFAtoken;

  return (
    <>
      <Header />
      {!isConfirmViewVisible && (
        <SubHeader
          title={t('Set new password')}
          fallbackLink='/'
        />
      )}
      <main className={classNames(styles.passwordConfig, {
        [styles.passwordConfig_withoutSubHeader]: isConfirmViewVisible,
      })}
      >
        <div className={styles.wrapper}>
          {isNeedToConfirmTwoFA ? (
            <form
              onSubmit={TFAFormHandleSubmit}
              className={styles.form}
            >
              <Input
                value={TFAFormValues.TFA}
                setValue={TFAFormHandleChange}
                id='TFA'
                label={t('Confirm 2FA code')}
                name='TFA'
                error={!!(TFAFormTouched.TFA && TFAFormErrors.TFA)}
                errorMessage={TFAFormErrors.TFA}
              />
              <Button
                type='submit'
                className={styles.button}
                disabled={TFAFormIsSubmitting}
              >
                {t('Confirm')}
              </Button>
            </form>
          ) : isConfirmViewVisible ? (
            <div className={styles.confirmation}>
              <div>
                <div className={styles.icon}>
                  <svg>
                    <use
                      xlinkHref={`${LockSVG}#lockSVG`}
                      href={`${LockSVG}#lockSVG`}
                    />
                  </svg>
                </div>
                <h3>
                  {t(successTitle)}
                </h3>
                <p>{t('You will be able to change it in your personal account')}</p>
              </div>
              <Button
                className={styles.button}
                link={token ? '/login' : '/d/account-settings'}
              >
                {t('Continue fill profile info')}
              </Button>
            </div>
          ) : (
            <form
              className={styles.form}
              onSubmit={handleSubmit}
            >
              {(userData?.user && !userData?.user.passEmpty && !token) && (
                <Input
                  value={values.oldPassword}
                  setValue={handleChange}
                  id='old-password'
                  label={t('Old password')}
                  name='oldPassword'
                  type='password'
                  error={!!(touched.oldPassword && errors.oldPassword)}
                  errorMessage={errors.oldPassword}
                />
              )}
              <div>
                <Input
                  value={values.newPassword}
                  setValue={handleChange}
                  id='new-password'
                  label={t('New password')}
                  name='newPassword'
                  type='password'
                  error={!!(touched.newPassword && errors.newPassword)}
                  errorMessage={errors.newPassword}
                />
                <PasswordStrength
                  password={values.newPassword}
                  rules={rules}
                />
              </div>
              <Input
                value={values.confirmNewPassword}
                setValue={handleChange}
                id='confirm-new-password'
                label={t('Confirm new password')}
                name='confirmNewPassword'
                type='password'
                error={!!(touched.confirmNewPassword && errors.confirmNewPassword)}
                errorMessage={errors.confirmNewPassword}
              />
              <Button
                type='submit'
                className={styles.button}
                disabled={isSubmitting || disabled}
              >
                {t(userSetPassword ? 'Set password' : 'Confirm change password')}
              </Button>
            </form>
          )}
        </div>
      </main>
    </>
  );
};
export default NewPassword;
