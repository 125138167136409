import { useSetAtom } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ComfortScoreSVG from '../../public/media/scores/Comfort score.svg';
import GradientProgressBar from '../GradientProgressBar/GradientProgressBar.tsx';
import { FilterInternal, internalFilter } from '../pages/NewProject/NewProjectDeliverables/deliverablesFilter.atom.ts';
import styles from './ComfortScore.module.scss';

type ComfortScoreProps = {
  value: number,
  hideAction?: boolean,
  className?: string,
};
const ComfortScore = ({ value, hideAction, className }: ComfortScoreProps) => {
  const { t } = useTranslation('scores');
  const setFilterInternal = useSetAtom(internalFilter);

  return (
    <div className={classNames(styles.score, className)}>
      <div
        className={styles.score__circle}
      >
        <GradientProgressBar
          value={value}
          strokeWidth={15}
        >
          <img
            src={ComfortScoreSVG}
            alt=''
            className={styles.score__icon}
          />
        </GradientProgressBar>
      </div>
      <h4 className={styles.score__title}>{t('Comfort Score')}</h4>
      <p className={styles.score__text}>
        {t('Your configuration currently gives you a Comfort Score of')}
        {` ${Number.isNaN(value) ? 0 : value}%.`}
        {value !== 100 && !hideAction && (
          <>
            {' '}
            <button
              type='button'
              className='link'
              onClick={() => setFilterInternal(prev => ({ ...prev, type: FilterInternal.UNCHECKED }))}
            >
              {t('Click here')}
            </button>
            {' '}
            {t('to reach your full potential.')}
          </>
        )}
      </p>
    </div>
  );
};

export default ComfortScore;
