import {
  GridSortItem,
} from '@mui/x-data-grid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai/index';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import apiClient from '../../apiClient.ts';
import { DEFAULT_DATE_FORMAT } from '../../constants.ts';
import DeleteIllustrationSVG from '../../public/media/delete-illustration.svg';
import PlusSVG from '../../public/media/plus.svg';
import SearchSVG from '../../public/media/search.svg';
import { userAtom } from '../../store/auth.ts';
import { languagesAtom, Locale } from '../../store/lang.ts';
import { NotificationStatus, notify } from '../../store/notifications.ts';
import { useDebounce } from '../../useDebounce.ts';
import usePage from '../../usePage.ts';
import Header from '../Header/Header.tsx';
import SubHeader from '../SubHeader/SubHeader.tsx';
import Button, { ButtonSize, ButtonVariants } from '../UIKit/Button/Button.tsx';
import Modal from '../UIKit/Modal/Modal.tsx';
import Popover, { PopoverPlacement } from '../UIKit/Popover/Popover.tsx';
import Table, { TableVariant } from '../UIKit/Table/Table.tsx';
import { addLocale, removeLocale } from './manageTranslationsAPI.ts';
import { TableColumnMenu } from './TableColumnMenu.tsx';
import { getTranslationsColumns } from './translationsColumns.tsx';
import { TranslationData, TranslationDataWithRows } from './types';
import styles from './MaintainTranslations.module.scss';
import SocketApi from '../../socketApiClient.ts';

const SearchFilterIcon = () => (
  <svg className={styles.searchFilterIcon}>
    <use
      xlinkHref={`${SearchSVG}#searchSVG`}
      href={`${SearchSVG}#searchSVG`}
    />
  </svg>
);

const MaintainTranslations = () => {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userData = useAtomValue(userAtom);
  const setLanguages = useSetAtom(languagesAtom);

  const LIMIT = 30;
  const { page, updatePage } = usePage();
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState(searchParams.get('search') || '');
  const debouncedSearch = useDebounce<string>(search, 300);
  const defaultSortingSettings = searchParams.get('order_by') !== null && searchParams.get('sort_direction') !== null
    ? { order_by: searchParams.get('order_by') || '', direction: searchParams.get('sort_direction') || '' }
    : null;
  const [sortingSettings, setSortingSettings] = useState<{ order_by: string, direction: string } | null>(defaultSortingSettings);
  const [filterSettings, setFilterSettings] = useState<Record<string, any> | null>(null);
  const debouncedFilterSettings = useDebounce<Record<string, any> | null>(filterSettings, 300);

  const {
    data,
    mutate: mutateTranslations,
    isLoading,
    isValidating,
  } = useSWR(
    ['maintain-translations/list', page, debouncedSearch, sortingSettings, debouncedFilterSettings, i18n.language],
    async (): Promise<TranslationDataWithRows> => {
      const params = new URLSearchParams({
        ...(page !== 1 ? { page: String(page) } : {}),
        per_page: String(LIMIT),
        ...(search.length > 0 ? { search } : {}),
        ...(sortingSettings || {}),
        filters: JSON.stringify(filterSettings),
      }).toString();
      const { response, statusCode } = await apiClient
        .get<TranslationData>(`maintain-translations/list?${params}&locale=en`);
      if (statusCode !== 200) {
        notify({ text: { body: response.message } });
      }
      if (response.meta.last_page < page) {
        window.location.href = '/not-found';
      }
      const rows = [
        ([...response.active_translations_locales, ...response.inactive_translations_locales])
          .reduce<{ [locale: string]: string | null }>((acc, curr) => {
          acc[curr.locale] = curr.latest_update
            ? dayjs(curr.latest_update).format(`${userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT} HH:mm:ss`) : '-';
          return { ...acc, id: crypto.randomUUID(), timestamp: 'true' };
        }, {}),
        ...(response.data || []),
      ];
      return { ...response, rows };
    },
    {
      keepPreviousData: true,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      onError: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    data && setTotalPages(data.meta.last_page);
  }, [data]);

  useEffect(() => {
    data && setLanguages(data.active_translations_locales.filter(language => language.is_publish));
  }, [data?.active_translations_locales]);

  useEffect(() => {
    setSearchParams(prev => {
      const queryParams = Object.fromEntries(prev.entries());
      if (debouncedSearch.length > 0) {
        queryParams.search = debouncedSearch;
      } else {
        delete queryParams.search;
      }
      delete queryParams.page;
      return { ...queryParams };
    });
  }, [debouncedSearch]);

  useEffect(() => {
    setSearchParams(prev => {
      const queryParams = Object.fromEntries(prev.entries());

      if (sortingSettings) {
        queryParams.order_by = sortingSettings.order_by;
        queryParams.sort_direction = sortingSettings.direction;
      } else {
        delete queryParams.order_by;
        delete queryParams.sort_direction;
      }
      return { ...queryParams };
    });
  }, [sortingSettings]);

  const onSort = (model: GridSortItem | null) => {
    setSortingSettings(model ? { order_by: model.field, direction: model.sort! } : null);
  };

  const [removedLocale, setRemovedLocale] = useState<null | Locale>(null);
  const onTranslationRemove = (locale: Locale) => {
    setRemovedLocale(locale);
  };

  const [closePopover, setClosePopover] = useState<() => void>(() => {});

  const closeCallback = (func: (e: Event) => void) => {
    setClosePopover(() => func);
  };

  const updateLocalTranslation = (id: number, value: string, locale: string) => {
    mutateTranslations((prevData) => {
      if (prevData) {
        const updatedRows = prevData?.rows.map((row) => {
          if ('id' in row && String(row.id) === String(id)) {
            return { ...row, [locale]: value };
          }
          return row;
        });
        return { ...prevData, rows: updatedRows };
      }
    }, false);
  };

  // // ai progress checker via websocket
  useEffect(() => {
    let sockets: SocketApi[];
    if (data?.active_translations_locales) {
      sockets = data?.active_translations_locales?.map((locale) => new SocketApi(locale?.websocket_url));
      sockets?.forEach((socket, i) => {
        socket.on('ai-translation-generation-event', ({ succeeded }: { succeeded: boolean }) => {
          const caption = data?.active_translations_locales[i].caption;
          notify({
            text: {
              title: t('AI translation generation'),
              body: (
                <Trans
                  defaults={
                    succeeded
                      ? 'AI translation generation for {{langCaption}} was successful.'
                      : 'AI translation generation for {{langCaption}} failed.'
                  }
                  values={{ langCaption: caption.toLowerCase() }}
                />
              ),
            },
            status: succeeded ? NotificationStatus.SUCCESS : NotificationStatus.ERROR,
          });
          succeeded && mutateTranslations();
        });
      });
    }
    () => {
      sockets?.forEach((socket) => socket.off());
    };
  }, [data]);
  // END ai progress checker via websocket

  return (
    <main className={styles.translations}>
      <Header />
      <SubHeader
        fallbackLink='/'
        title={t('Translations')}
      />
      <div className={styles.main}>
        <header className={styles.header}>
          <div className={styles.search}>
            <svg className={styles.search__searchIcon}>
              <use
                xlinkHref={`${SearchSVG}#searchSVG`}
                href={`${SearchSVG}#searchSVG`}
              />
            </svg>
            <input
              value={search}
              type='text'
              onChange={e => setSearch(e.target.value)}
              className={classNames(styles.search__input, {})}
              placeholder={t('Search translations')}
            />
          </div>
          <div className={styles.header__controls}>
            <Popover
              placement={PopoverPlacement.CONTEXT_MENU}
              disabled={data?.inactive_translations_locales.length === 0}
              triggerButton={(
                <Button
                  variant={ButtonVariants.SECONDARY}
                  size={ButtonSize.SMALL}
                  disabled={data?.inactive_translations_locales.length === 0}
                  icon={(
                    <svg>
                      <use
                        xlinkHref={`${PlusSVG}#plusSVG`}
                        href={`${PlusSVG}#plusSVG`}
                      />
                    </svg>
                  )}
                  iconSize={{ width: 12, height: 12 }}
                  uiMode
                >
                  {t('Add language')}
                </Button>
              )}
              closeCallback={closeCallback}
            >
              <div className={styles.localesList}>
                {data?.inactive_translations_locales.map(locale => (
                  <button
                    className={styles.localeItem}
                    type='button'
                    key={locale.locale}
                    onClick={async () => {
                      await addLocale({
                        localeId: locale.id,
                        t,
                      });
                      await mutateTranslations();
                      closePopover();
                    }}
                  >
                    <img
                      className={styles.flagIcon}
                      src={locale.icon}
                      alt={locale.caption}
                    />
                    {locale.caption}
                  </button>
                ))}
              </div>
            </Popover>
          </div>
        </header>
        <div className={styles.content}>
          <Table
            rows={data?.rows}
            isLoading={isLoading || isValidating}
            onPageChange={updatePage}
            totalPages={totalPages}
            columns={getTranslationsColumns({
              t,
              locales: data?.active_translations_locales,
              page,
              limit: LIMIT,
              updateLocalTranslation,
            })}
            page={page}
            customRowId='id'
            onSortModelChange={onSort}
            variant={TableVariant.SIMPLE}
            initSortModel={sortingSettings ? {
              field: sortingSettings.order_by,
              sort: sortingSettings.direction,
            } as GridSortItem : undefined}
            onFilterModelChange={setFilterSettings} // eslint-disable-next-line react/no-unstable-nested-components
            columnMenu={(props) => (
              <TableColumnMenu
                props={props}
                locale={data!.active_translations_locales.find(locale => locale.locale === props.colDef.field)!}
                onTranslationRemove={onTranslationRemove}
                mutateTranslations={mutateTranslations}
              />
            )}
            customFilter={{
              title: 'Search translation',
              icon: SearchFilterIcon,
            }}
          />
        </div>
      </div>
      {removedLocale && (
        <Modal
          closeModal={() => setRemovedLocale(null)}
          className={styles.deleteModal}
        >
          <svg className={styles.deleteModal__illustration}>
            <use
              xlinkHref={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
              href={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
            />
          </svg>
          <h3 className={styles.deleteModal__title}>
            {t('Are you sure you want to delete the “{{caption}}” translations option?', { caption: removedLocale.caption })}
          </h3>
          <footer className={styles.deleteModal__footer}>
            <Button
              onClick={() => setRemovedLocale(null)}
              variant={ButtonVariants.SECONDARY}
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={async () => {
                await removeLocale({ t, localeId: removedLocale.id });
                setRemovedLocale(null);
                await mutateTranslations();
              }}
              variant={ButtonVariants.PRIMARY}
            >
              {t('Yes, delete')}
            </Button>
          </footer>
        </Modal>
      )}
    </main>
  );
};

export default MaintainTranslations;
