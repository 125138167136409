import {
  Outlet, useLoaderData, useLocation, useParams,
} from 'react-router-dom';
import { atom, useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import useEditAccessHandler from '../../../utils/useEditAccessHandler.ts';
import { ProjectResource } from '../Projects/types.ts';
import NewProjectTabs from './NewProjectTabs/NewProjectTabs.tsx';
import { NewProjectContext, ProgressAtomsType } from './types.ts';
import { StatusState } from '../../UIKit/StatusLabel/types.ts';
import Loader from '../../Loader/Loader.tsx';
import { useShortPolling } from '../../../useShortPolling.ts';
import apiClient from '../../../apiClient.ts';
import ResetManualChangesConfirm from './ResetManualChangesConfirm.tsx';
import { pollingSettingsAtom, projectEditAccess, projectIdAtom } from '../../../store/project.ts';
import ConfirmPresenceModal from '../../ConfirmPresenceModal/ConfirmPresenceModal.tsx';
import { useElementHeightCssVariable } from '../../../useElementHeightCssVariable.ts';
import ConfirmWithWarning from '../../UIKit/ConfirmWithWarning/ConfirmWithWarning.tsx';
import { HistoryState } from '../Projects/Projects.tsx';
import { ProjectPermissions } from '../Login/user.props.ts';
import ProjectHistory from '../Projects/ProjectHistory/ProjectHistory.tsx';

import styles from './NewProject.module.scss';

const interactionBlockerInitialState = { isVisible: false, callback: null };

const NewProject = () => {
  const initialProject = (useLoaderData() as { project: ProjectResource })?.project;
  const [projectAtom] = useState(atom(initialProject));
  const [project, setProject] = useAtom(projectAtom);
  const storedProjectId = useAtomValue(projectIdAtom);
  const projId = storedProjectId ?? project?.id;

  const [isAiLoadingScreenVisible, setIsAiLoadingScreenVisible] = useState<boolean>(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [isAiLoadingScreenVisible]);
  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);
  const path = useLocation().pathname;

  const { t } = useTranslation();
  const { clientId, projectId } = useParams();

  useEffect(() => {
    if (initialProject) {
      setProject(initialProject);
    }
  }, [initialProject?.id]);

  const [interactionBlocker, setInteractionBlocker] = useState<{ isVisible: boolean, callback:(() => void) | null }>(
    interactionBlockerInitialState);

  const [pollingSettings, setPollingSettings] = useAtom(pollingSettingsAtom);
  const [
    manualChangesConfirmationSettings,
    setManualChangesConfirmationSettings,
  ] = useState<{ isVisible: boolean; callback:((resetChanges: boolean) => void) | null }>({ isVisible: false, callback: null });

  useEffect(() => {
    if (pollingSettings) {
      if (pollingSettings?.isTriggered) {
        setInteractionBlocker(prev => ({ ...prev, isVisible: true }));
      } else {
        interactionBlocker?.callback?.();
        setInteractionBlocker(prev => ({ ...prev, isVisible: false }));
      }
    }
  }, [pollingSettings?.isTriggered]);

  const progressAtoms: ProgressAtomsType = {
    parameters: atom(project?.step > 0 ? 100 : 0),
    questions: atom(project?.step > 1 ? 100 : 0),
    deliverables: atom(project?.step > 2 ? 100 : 0),
  };

  useEffect(() => {
    project?.status?.state === StatusState.PENDING && setPollingSettings({ ...pollingSettings, isTriggered: true });
  }, [project]);

  useShortPolling(
    async () => {
      const { response } = await apiClient.get<{ data: ProjectResource }>(
        `clients/${clientId}/projects/${projId}?with=customer,template,answers,tasks`,
      );
      const state = response?.data?.status?.state;

      if (state !== StatusState.PENDING) {
        setProject({ ...project, ...response.data });
        pollingSettings?.callback?.(response);
        setPollingSettings(prev => ({ ...prev, isTriggered: false }));
      }
    },
    {
      isEnabled: pollingSettings?.isTriggered,
      dependencies: [pollingSettings?.isTriggered],
    },
  );

  const {
    areYouStillHereModal,
    confirmPresenceHandler,
    valueOfTimer,
    numberOfTimer,
    getOutHandler,
    getEditAccess,
    accessCheckInterval,
  } = useEditAccessHandler(
    clientId,
    projectId,
    storedProjectEditAccess,
    setStoredProjectEditAccess,
    project?.permissions?.includes(ProjectPermissions.UPDATE),
  );

  useEffect(() => {
    if (interactionBlocker.isVisible && initialProject?.id && !storedProjectEditAccess?.isViewMode) {
      const intervalId = setInterval(() => {
        getEditAccess();
      }, accessCheckInterval);

      return () => clearInterval(intervalId);
    }
  }, [interactionBlocker.isVisible]);

  const isProjectManualyChanged = project?.schedule_edited;
  const isSummaryPage = path.includes('summary');

  const warningRef = useElementHeightCssVariable('--new-project-warning-height');

  const [historyForProject, setHistoryForProject] = useState<HistoryState | null>(null);

  return (
    <>
      <section
        className={classNames(styles.newProject, {
          [styles.newProject_pending]: interactionBlocker.isVisible,
          [styles.newProject_withWarning]: isProjectManualyChanged,
        })}
      >
        {!isAiLoadingScreenVisible && (
          <NewProjectTabs
            project={project}
            progressAtoms={progressAtoms}
          />
        )}
        {isProjectManualyChanged && !isSummaryPage && (
          <ConfirmWithWarning
            className={styles.warning}
            t={t}
            ref={warningRef}
          >
            <Trans
              // eslint-disable-next-line max-len
              defaults='This project has a manually edited schedule. To prevent conflicts in dependencies, the project can not be reconfigured. To reconfigure, please restore an <btn>unedited version</btn>.'
              components={{
                btn: <button
                  className={styles.warning__link}
                  onClick={() => setHistoryForProject({
                    id: project?.id,
                    name: project?.caption,
                    isAllowedToRestore: project.permissions.includes(ProjectPermissions.RESTORE),
                  })}
                  type='button'
                />,
              }}
            />
          </ConfirmWithWarning>
        )}
        <div className={styles.wrapper}>
          <Outlet
            context={{
              progressAtoms,
              projectAtom,
              setInteractionBlocker,
              pollingSettings,
              setPollingSettings,
              setManualChangesConfirmationSettings,
              isAiLoadingScreenVisible,
              setIsAiLoadingScreenVisible,
              getEditAccess,
            } as NewProjectContext}
          />
        </div>
      </section>
      {interactionBlocker.isVisible && (
        <div className={styles.loader}>
          <Loader className={styles.loader__icon} />
          <p className={styles.loader__text}>
            {t(`Please wait a moment. ${project?.step === 3 ? 'The project\'s tasks are being scheduled.' : 'Saving draft.'}`)}
          </p>
        </div>
      )}
      {manualChangesConfirmationSettings.isVisible && (
        <ResetManualChangesConfirm
          keepChanges={() => manualChangesConfirmationSettings?.callback?.(false)}
          recalculateSchedule={() => manualChangesConfirmationSettings?.callback?.(true)}
        />
      )}
      {areYouStillHereModal.isVisible && (
        <ConfirmPresenceModal
          {...{
            getOutHandler,
            confirmPresenceHandler,
            isShowGradientProgressBar: areYouStillHereModal.isVisible,
            valueOfTimer,
            numberOfTimer,
            t,
          }}
        />
      )}
      <ProjectHistory
        project={historyForProject}
        close={() => setHistoryForProject(null)}
        updateProjects={() => {
          setPollingSettings({ ...pollingSettings, isTriggered: true });
        }}
      />
    </>
  );
};

export default NewProject;
