import React, { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import Header from '../../Header/Header.tsx';
import styles from './UserManual.module.scss';
import ChevronLeftSVG from '../../../public/media/chevron-left.svg';

const UserManualContent = lazy(() => import('./UserManualContent/UserManualContent.tsx'));

const UserManual = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div
        className={styles.routeWrapper}
      >
        <Button
          className={styles.routeWrapperBtn}
          onClick={() => (window.history.length > 2 ? navigate(-1) : navigate('/'))}
        >
          <svg>
            <use
              xlinkHref={`${ChevronLeftSVG}#shevronLeftSvg`}
              href={`${ChevronLeftSVG}#shevronLeftSvg`}
            />
          </svg>
        </Button>
        <p
          className={styles.routeTitle}
        >
          {t('Application manual')}
        </p>
      </div>
      <Suspense>
        <UserManualContent />
      </Suspense>
    </>
  );
};
export default UserManual;
