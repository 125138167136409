import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Outlet, useLocation, useOutletContext, useParams,
} from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from '@mui/material';

import InfoSVG from '../../../../public/media/info.svg';
import ChartSVG from '../../../../public/media/chart-list.svg';
import GanttSVG from '../../../../public/media/gantt.svg';
import AngleDownSVG from '../../../../public/media/angle-down.svg';
import DocumentSVG from '../../../../public/media/document.svg';

import { MOBILE_MEDIA_QUERY } from '../../../../constants';
import { NewProjectContext } from '../types';
import { Permissions } from '../../Login/user.props';
import { usePermissions } from '../../../../usePermission';

import { PopoverPlacement } from '../../../UIKit/Popover/Popover';
import PopoverOptions from '../../../UIKit/PopoverOptions/PopoverOptions';
import TabLink, { ITab } from './TabLink/TabLink';

import { arrayAt } from '../../../../utils/arrayAt';

import styles from './NewProjectSummary.module.scss';

const tabs: ITab[] = [
  {
    to: '',
    isEnd: true,
    icon: `${InfoSVG}#infoSVG`,
    title: 'Dashboard',
  },
  {
    to: 'schedule',
    isEnd: false,
    icon: `${GanttSVG}#ganttSVG`,
    title: 'Project schedule',
  },
  {
    to: 'quality-gates',
    isEnd: false,
    icon: `${ChartSVG}#chartSVG`,
    title: 'Quality gates',
  },
];

const NewProjectSummary = () => {
  const { projectAtom, setPollingSettings, pollingSettings } = useOutletContext<NewProjectContext>();
  const project = useAtomValue(projectAtom);
  const { t } = useTranslation();
  const location = useLocation();
  const { clientId, projectId } = useParams();
  const { hasPermission } = usePermissions();

  const [currentRoute, setCurrentRoute] = useState<string>();

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  useEffect(() => {
    setCurrentRoute(arrayAt(location.pathname.split('/'), -1));
  }, [location]);

  const getPopoverOptions = () => tabs.map((tab, index) => ({
    id: index,
    title: <TabLink
      tab={tab}
      isPopoverOption
      t={t}
    />,
    handler: () => {
    },
  }));

  const renderPopoverButton = () => {
    if (!currentRoute) return null;

    const selectedTab = tabs.find(tab => tab.to === currentRoute) ?? tabs[0];

    return (
      <div className={styles.popoverButton__content}>
        <svg className={styles.navigation__mobile__icon}>
          <use
            xlinkHref={selectedTab?.icon}
            href={selectedTab?.icon}
          />
        </svg>
        {t(selectedTab?.title ?? '')}
        <svg className={styles.downloadButton__arrow}>
          <use
            xlinkHref={`${AngleDownSVG}#angleDownSVG`}
            href={`${AngleDownSVG}#angleDownSVG`}
          />
        </svg>
      </div>
    );
  };

  return (
    <div className={styles.summary}>
      <header className={classNames(styles.header, { [styles.header_noMargin]: location.pathname.includes('schedule') })}>
        <div className={styles.header__pageInfo}>
          <h2 className={styles.pageType}>{t('Project summary')}</h2>
          <div className={styles.header__projectBlock}>
            <div className={classNames(styles.projectName, {
              [styles.projectName_withLink]: hasPermission(Permissions.PROJECT_TRACKING),
            })}
            >
              <svg className={styles.projectName__icon}>
                <use
                  xlinkHref={`${DocumentSVG}#documentSVG`}
                  href={`${DocumentSVG}#documentSVG`}
                />
              </svg>
              <h3 className={styles.projectName__title}>{project.caption}</h3>
            </div>
            {hasPermission(Permissions.PROJECT_TRACKING) && (
            <Link
              className={styles.header__projectTrackingLink}
              to={`/d/client/${clientId}/project/${projectId}/tracking`}
            >
              {t('Project tracking overview')}
            </Link>
            )}
          </div>
        </div>
        {isMobileDevice ? (
          <section className={styles.navigation__mobile}>
            <PopoverOptions
              customButton={renderPopoverButton()}
              options={getPopoverOptions()}
              paperClassName={styles.navigation__mobile__paper}
              placement={PopoverPlacement.CONTEXT_MENU_LEFT}
            />
          </section>
        ) : (
          <nav className={styles.navigation}>
            {tabs.map(tab => (
              <TabLink
                key={tab.icon}
                tab={tab}
                t={t}
              />
            ))}
          </nav>
        )}
      </header>
      <Outlet context={{ projectAtom, setPollingSettings, pollingSettings }} />
    </div>
  );
};

export default NewProjectSummary;
