import classNames from 'classnames';
import dayjs from 'dayjs';
import { FormikValues, useFormik } from 'formik';
import { useAtomValue } from 'jotai/index';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useParams } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants.ts';
import CalendarSVG from '../../../../../../public/media/calendar_check.svg';
import { ReactComponent as CheckedSVG } from '../../../../../../public/media/checked.svg';
import { ReactComponent as EditSVG } from '../../../../../../public/media/edit-icon.svg';
import { ReactComponent as RiskSVG } from '../../../../../../public/media/risk-custom.svg';
import { ReactComponent as PlusSVG } from '../../../../../../public/media/plus.svg';
import UserSVG from '../../../../../../public/media/user.svg';
import { userAtom } from '../../../../../../store/auth.ts';

import { NotificationStatus, notify } from '../../../../../../store/notifications';
import Button, { ButtonVariants } from '../../../../../UIKit/Button/Button';
import DatePicker from '../../../../../UIKit/DatePicker/DatePicker.tsx';
import Input from '../../../../../UIKit/Input/Input';
import { IProjectRisk } from '../types';
import styles from './EditRiskForm.module.scss';
import apiClient from '../../../../../../apiClient';

interface IFieldWrapperProps {
  t: (key: string) => string;
  label: string;
  name: string;
  icon: ReactNode;
  isEditMode: boolean;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetValue: () => void;
  error: boolean;
  errorMessage: string;
  inputLabel: string;
  toggleEditMode: () => void;
  isDatepicker?: boolean;
  setTouched?: (field: { [field: string]: boolean }) => void;
  setFieldValue?: (field: string, value: any) => void;
}

const FieldWrapper = ({
  t,
  label,
  name,
  icon,
  resetValue,
  isEditMode,
  value,
  handleChange,
  handleBlur,
  inputLabel,
  error,
  errorMessage,
  toggleEditMode,
  isDatepicker = false,
  setTouched,
  setFieldValue,
}: IFieldWrapperProps) => {
  const userData = useAtomValue(userAtom);

  return (
    <div className={classNames(styles.fieldWrapper, { [styles.fieldWrapper_empty]: !isEditMode })}>
      <header className={styles.fieldWrapper__header}>
        <label
          className={styles.fieldWrapper__header__label}
          htmlFor={name}
        >
          {icon}
          {t(label)}
        </label>

        {isEditMode
          ? (
            <button
              type='button'
              className={styles.actionButton}
              onClick={resetValue}
              disabled={error}
            >
              {t('Cancel edit')}
            </button>
          ) : value
            ? (
              <Button
                className={classNames(styles.actionButton, styles.editButton)}
                type='button'
                onClick={toggleEditMode}
              >
                <EditSVG />
              </Button>
            )
            : (
              <button
                type='button'
                className={classNames(styles.actionButton, styles.actionButton_add)}
                onClick={toggleEditMode}
              >
                <PlusSVG />
                {t('Add description')}
              </button>
            )}

      </header>
      <div className={styles.fieldWrapper__field}>
        {isEditMode
          ? (
            isDatepicker ? (
              <DatePicker
                label={t(inputLabel)}
                format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
                value={dayjs(value as unknown as dayjs.Dayjs | null)}
                setValue={date => {
                  setTouched!({ due_date: true });
                  setFieldValue!('due_date', date);
                }}
                error={error}
                errorMessage={errorMessage}
                disablePast
              />
            )
              : (
                <Input
                  type='textarea'
                  fullWidth
                  value={value}
                  setValue={handleChange}
                  onBlur={handleBlur}
                  id={name}
                  label={t(inputLabel)}
                  name={name}
                  error={error}
                  errorMessage={errorMessage}
                  className={styles.form__input}
                  multiline
                  maxRows={3}
                />
              )
          ) : (
            <p className={classNames(styles.form__fieldValue, { [styles.form__fieldValue_empty]: !value })}>
              {(isDatepicker ? dayjs(value).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT) : value) || t(inputLabel)}
            </p>
          )}
      </div>
    </div>
  );
};

interface FormValues {
  caption: string;
  explanation: string;
  mitigation: string;
  owner: string;
  due_date: string | null;
}

interface IEditRiskFormProps {
  closeForm: () => void;
  riskData: IProjectRisk;
  onRiskEdit: (editedRisk: IProjectRisk) => void;
}

const EditRiskForm = ({ closeForm, riskData, onRiskEdit }: IEditRiskFormProps) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [activeFieldForEdit, setActiveFieldForEdit] = useState<keyof FormValues | null>(null);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    setTouched,
    errors,
    isSubmitting,
    isValid,
    dirty,
  }: FormikValues = useFormik<FormValues>({
    validateOnMount: true,
    initialValues: {
      caption: riskData?.caption || '',
      explanation: riskData?.explanation || '',
      mitigation: riskData?.mitigation || '',
      owner: riskData?.owner || '',
      due_date: riskData?.due_date || null,
    },
    validationSchema: Yup.object<FormValues>({
      caption: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .required(t('Risk item is required')),
      explanation: Yup.string()
        .trim()
        .max(500, t('Must be 500 characters at most'))
        .required(t('Risk explanation is required')),
      mitigation: Yup.string()
        .trim()
        .max(500, t('Must be 500 characters at most'))
        .required(t('Risk mitigation is required')),
      owner: Yup.string().required(t('Owner is required')),
      due_date: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(dayjs().startOf('day').toDate(), t('Due date can not be set earlier than today'))
        .required(t('Due date is required')),
    }),
    onSubmit: async (submitValues: FormValues) => {
      try {
        const { statusCode, response } = await apiClient.put<{ data: IProjectRisk }>(`projects/${projectId}/risks/${riskData.id}`, {
          body: JSON.stringify({ ...submitValues, due_date: dayjs(submitValues.due_date).format(DEFAULT_DATE_FORMAT) }),
        });

        if (statusCode === 200) {
          onRiskEdit(response.data);
          notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success!'), body: t('Risk edited') } });
          closeForm();
        } else {
          throw new Error();
        }
      } catch (e) {
        notify(e?.message ? { text: { body: e.message } } : {});
        console.error(e.message);
      }
    },
  });

  const resetFieldValueToOriginal = (fieldName: keyof FormValues): void => {
    setFieldValue(fieldName, riskData[fieldName]);
    setActiveFieldForEdit(null);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__content}>
        <FieldWrapper
          isEditMode={activeFieldForEdit === 'caption'}
          t={t}
          value={values.caption}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name='caption'
          error={!!(touched.caption && errors.caption)}
          errorMessage={errors.caption}
          inputLabel={t('Name')}
          label={t('Risk Item')}
          icon={<RiskSVG />}
          resetValue={() => resetFieldValueToOriginal('caption')}
          toggleEditMode={() => setActiveFieldForEdit('caption')}
        />

        <FieldWrapper
          isEditMode={activeFieldForEdit === 'explanation'}
          t={t}
          value={values.explanation}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name='explanation'
          error={!!(touched.explanation && errors.explanation)}
          errorMessage={errors.explanation}
          inputLabel={t('Add an explanation')}
          label={t('Explanation')}
          icon={<RiskSVG />}
          resetValue={() => resetFieldValueToOriginal('explanation')}
          toggleEditMode={() => setActiveFieldForEdit('explanation')}
        />

        <FieldWrapper
          isEditMode={activeFieldForEdit === 'mitigation'}
          t={t}
          value={values.mitigation}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name='mitigation'
          error={!!(touched.mitigation && errors.mitigation)}
          errorMessage={errors.mitigation}
          inputLabel={t('Add mitigation actions')}
          label={t('Mitigation actions')}
          icon={<CheckedSVG />}
          resetValue={() => resetFieldValueToOriginal('mitigation')}
          toggleEditMode={() => setActiveFieldForEdit('mitigation')}
        />

        <FieldWrapper
          isEditMode={activeFieldForEdit === 'owner'}
          t={t}
          value={values.owner}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name='owner'
          error={!!(touched.owner && errors.owner)}
          errorMessage={errors.owner}
          inputLabel={t('Add owner')}
          label={t('Owner')}
          icon={(
            <svg>
              <use
                xlinkHref={`${UserSVG}#userSVG`}
                href={`${UserSVG}#userSVG`}
              />
            </svg>
          )}
          resetValue={() => resetFieldValueToOriginal('owner')}
          toggleEditMode={() => setActiveFieldForEdit('owner')}
        />

        <FieldWrapper
          isDatepicker
          isEditMode={activeFieldForEdit === 'due_date'}
          t={t}
          value={values.due_date}
          handleChange={handleChange}
          handleBlur={handleBlur}
          name='due_date'
          error={!!(touched.due_date && errors.due_date)}
          errorMessage={errors.due_date}
          inputLabel={t('Add due date')}
          label={t('Due date')}
          icon={(
            <svg>
              <use
                xlinkHref={`${CalendarSVG}#calendarSVG`}
                href={`${CalendarSVG}#calendarSVG`}
              />
            </svg>
          )}
          resetValue={() => resetFieldValueToOriginal('due_date')}
          toggleEditMode={() => setActiveFieldForEdit('due_date')}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
        />
      </div>

      <div className={styles.form__footer}>
        <Button
          variant={ButtonVariants.SECONDARY}
          className={styles.button}
          type='button'
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={closeForm}
        >
          {t('Exit')}
        </Button>
        <Button
          variant={ButtonVariants.PRIMARY}
          className={styles.button}
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting || !(isValid && dirty)}
        >
          {t('Save changes')}
        </Button>
      </div>
    </form>
  );
};

export default EditRiskForm;
