import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header/Header.tsx';
import styles from './PasswordConfig.module.scss';
import Input from '../../UIKit/Input/Input';
import Button from '../../UIKit/Button/Button';
import SubHeader from '../../SubHeader/SubHeader.tsx';
import apiClient from '../../../apiClient.ts';
import LockSVG from '../../../public/media/lock.svg';
import { notify } from '../../../store/notifications.ts';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [emailValue, setEmailValue] = useState<string>('');
  const [isConfirmViewVisible, setIsConfirmViewVisible] = useState<boolean>(false);

  const sendEmail = async () => {
    try {
      const { statusCode } = await apiClient.withoutAuth().post('user/forgot-password', {
        body: JSON.stringify({ email: emailValue }),
      });

      switch (statusCode) {
        case 200:
          setIsConfirmViewVisible(true);
          break;
        case 429:
          notify({ text: { title: t('An error occurred'), body: t('The email was already sent. Please, try again later') } });
          break;
        default:
          throw new Error();
      }
    } catch (e) {
      notify();
    }
  };

  return (
    <>
      <Header />
      {!isConfirmViewVisible && (
        <SubHeader
          title={t('Forgot password')}
          fallbackLink='/'
        />
      )}
      <main className={styles.passwordConfig}>
        <div className={styles.wrapper}>
          {isConfirmViewVisible ? (
            <div className={styles.confirmation}>
              <div>
                <div className={styles.icon}>
                  <svg>
                    <use
                      xlinkHref={`${LockSVG}#lockSVG`}
                      href={`${LockSVG}#lockSVG`}
                    />
                  </svg>
                </div>
                <h3>{t('Reset password link was successfully sent!')}</h3>
                <p>{t('Please, check your email for details')}</p>
              </div>
              <Button
                className={styles.button}
                link='/login'
              >
                {t('Continue')}
              </Button>
            </div>
          ) : (
            <form
              className={styles.form}
              onSubmit={e => {
                e.preventDefault();
                sendEmail();
              }}
            >
              <Input
                value={emailValue}
                setValue={(e) => setEmailValue(e.target.value)}
                required
                id='email'
                label={t('Enter your email')}
                type='email'
              />
              <Button
                type='submit'
                className={styles.button}
              >
                {t('Send email')}
              </Button>
            </form>
          )}
        </div>
      </main>
    </>
  );
};

export default ForgotPassword;
