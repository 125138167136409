import { useAtomValue } from 'jotai/index';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import DownloadSVG from '../../../../../public/media/simple-download.svg';
import { userClientAtom } from '../../../../../store/auth.ts';
import Popover, { PopoverPlacement } from '../../../../UIKit/Popover/Popover.tsx';
import styles from './DeliverableOptionsDropdown.module.scss';
import DotsSVG from '../../../../../public/media/dots.svg';
import DeleteSVG from '../../../../../public/media/delete-icon.svg';
import { ProjectPermissions } from '../../../Login/user.props.ts';

type DeliverableOptionsDropdownProps = {
  permissions: string[],
  isReadOnly: boolean,
  onDeleteClick: () => void,
  onDownload: () => void,
  showDownloadButton: boolean
};

const DeliverableOptionsDropdown = ({
  permissions, isReadOnly, onDeleteClick, onDownload, showDownloadButton,
}: DeliverableOptionsDropdownProps) => {
  const { t } = useTranslation();
  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });
  const currentClient = useAtomValue(userClientAtom);

  const closeCallback = (func: (e: Event) => void) => {
    setClosePopover(() => func);
  };

  if (!permissions.includes(ProjectPermissions.DELETE) || !permissions.includes(ProjectPermissions.UPDATE)) return null;

  return (
    <Popover
      placement={PopoverPlacement.RIGHT}
      paperClassName={styles.paper}
      triggerButton={(
        <div className={styles.trigger}>
          <svg>
            <use
              xlinkHref={`${DotsSVG}#dotsSVG`}
              href={`${DotsSVG}#dotsSVG`}
            />
          </svg>
        </div>
      )}
      closeCallback={closeCallback}
    >
      <div className={styles.menu}>
        {currentClient.premium_license && showDownloadButton && (
        <button
          type='button'
          onClick={() => {
            onDownload();
            closePopover();
          }}
        >
          <svg>
            <use
              xlinkHref={`${DownloadSVG}#simpleDownloadSVG`}
              href={`${DownloadSVG}#simpleDownloadSVG`}
            />
          </svg>
          {t('Download template')}
        </button>
        )}
        {permissions.includes(ProjectPermissions.DELETE) && !isReadOnly && (
          <button
            type='button'
            onClick={() => {
              onDeleteClick();
              closePopover();
            }}
          >
            <svg>
              <use
                xlinkHref={`${DeleteSVG}#deleteSVG`}
                href={`${DeleteSVG}#deleteSVG`}
              />
            </svg>
            {t('Remove')}
          </button>
        )}
      </div>
    </Popover>
  );
};

export default DeliverableOptionsDropdown;
