import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { lazy, Suspense, useState } from 'react';
import { mutate } from 'swr';
import Loader from '../../Loader/Loader';
import Drawer from '../../UIKit/Drawer/Drawer';
import UserForm from '../../UserForm/UserForm';
import PopoverOptions from '../../UIKit/PopoverOptions/PopoverOptions.tsx';
import UsersBulkUploadForm from './UsersBulkUploadForm.tsx';

import AngleDownSVG from '../../../public/media/angle-down.svg';
import ExcelSVG from '../../../public/media/excel-icon.svg';
import UserSVG from '../../../public/media/user.svg';

import apiClient from '../../../apiClient.ts';
import styles from './User.module.scss';
import { getFormattedServerErrors } from '../../../utils';
import { NotificationStatus, notify } from '../../../store/notifications.ts';

const UsersList = lazy(() => import('./UsersList/UsersList'));

const Users = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [isSingleUserDrawerOpen, setIsSingleUserDrawerOpenn] = useState(false);
  const [isBulkUploadDrawerOpen, setIsBulkUploadDrawerOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>();
  const [isCreateSubmiting, setIsCreateSubmiting] = useState(false);

  const createUser = async (body: Record<string, any>) => {
    try {
      setIsCreateSubmiting(true);
      const { statusCode, response } = await apiClient
        .post<{ errors?: Record<string, string[]> }>(`clients/${params.id}/members`, {
        body: JSON.stringify(body),
      });

      if (statusCode === 201) {
        await mutate((key: any[]) => key.includes('clients/members'), undefined, { revalidate: true });
        setIsSingleUserDrawerOpenn(false);
      } else if (response.errors) {
        setErrors(getFormattedServerErrors(response.errors));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsCreateSubmiting(false);
    }
  };

  const onBulkUpload = async (body: Record<string, any>) => {
    try {
      const formData = new FormData();
      formData.append('file', body.file);
      const { statusCode, ...response } = await apiClient.post<{ errors?: Record<string, string[]>; message?: string }>(
        `clients/${params.id}/bulk-members`,
        {
          body: formData,
        },
      );

      if (statusCode === 201 || statusCode === 200) {
        await mutate((key: any[]) => key.includes('clients/members'), undefined, { revalidate: true });
        setIsBulkUploadDrawerOpen(false);
        notify({ status: NotificationStatus.SUCCESS, text: { title: t('Users added'), body: t('Read .xls successfully completed') } });
      }
      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const options = [
    {
      id: 1,
      title: (
        <>
          <svg className={styles.popover__option__icon}>
            <use
              xlinkHref={`${UserSVG}#userSVG`}
              href={`${UserSVG}#userSVG`}
            />
          </svg>
          {t('Create single user')}
        </>
      ),
      handler: () => setIsSingleUserDrawerOpenn(true),
    },
    {
      id: 2,
      title: (
        <>
          <svg className={styles.popover__option__icon}>
            <use
              xlinkHref={`${ExcelSVG}#excelIcon`}
              href={`${ExcelSVG}#excelIcon`}
            />
          </svg>
          {t('Bulk upload (import .xls)')}
        </>
      ),
      handler: () => setIsBulkUploadDrawerOpen(true),
    },
  ];
  return (
    <section className={styles.users}>
      <div className={styles.heading}>
        <h5>{t('Users')}</h5>
        <PopoverOptions
          options={options}
          paperClassName={styles.popover__paper}
          customButton={(
            <div className={styles.createUserBtn}>
              <span>{t('Create new user')}</span>
              <svg className={styles.angleSVG}>
                <use
                  xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                  href={`${AngleDownSVG}#angleDownSVG`}
                />
              </svg>
            </div>
          )}
        />
      </div>
      <Drawer
        isOpen={isSingleUserDrawerOpen}
        setIsOpen={setIsSingleUserDrawerOpenn}
        title={t('Create new user')}
        className={styles.drawer}
      >
        <UserForm
          submitForm={createUser}
          submitButtonText={t('Create user')}
          outerErrors={errors}
          isSubmitting={isCreateSubmiting}
        />
      </Drawer>
      <Drawer
        isOpen={isBulkUploadDrawerOpen}
        setIsOpen={setIsBulkUploadDrawerOpen}
        title={t('Bulk upload users')}
        className={styles.drawer}
      >
        <UsersBulkUploadForm submitForm={onBulkUpload} />
      </Drawer>
      <Suspense fallback={<Loader className={styles.loader} />}>
        <UsersList />
      </Suspense>
    </section>
  );
};

export default Users;
